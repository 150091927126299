import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { switchMapWithLoading } from 'src/app/services/switch-with-map-loading';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'azure-multiple-selection',
    templateUrl: './azure-multiple-selection.component.html',
    styleUrls: ['./azure-multiple-selection.component.scss']
    // ,'../azure-input-search/azure-input-search.component.scss'
    ,
    standalone: true,
    imports: [NgIf, NgFor, FormsModule, AsyncPipe]
})
export class AzureMultipleSelectionComponent implements OnInit {
  @ViewChild('menuDropdownEl', { read: ElementRef, static: false })  menuDropdownEl!: ElementRef;
  constructor( private commonService: CommonService, private renderer:Renderer2) { 
    this.renderer.listen('window', 'click', (e: Event) => {
      if(this.menuDropdownEl){
        // this.body.appendChild(this.menuDropdownEl.nativeElement);
        if(!this.menuDropdownEl.nativeElement.contains(e.target)){
          if (!this.menuBtnClick ) {
            this.expanded = false;
            this.menuOpen = false;
          }
          this.menuBtnClick = false;
        }
      }
     
    });

  }

  searchValue:string = '';
  public searchText$ = new Subject<string>();
  @Output() selectAzureUserEvent = new EventEmitter<any>();
  azureUsers$!: Observable<any>;
  @ViewChild('searchEl') searchElement!: ElementRef;

  @ViewChild('azureElem') azureElem!: ElementRef;
  searchStyle:string = '';

  selectedUsers:any = [];
  @Input()model:any;
  @Input()viewType:string = 'form';
  @Input()appendTo:string = '';
  @Input()required:boolean = false;

  @Input() disabled:boolean = false;

  ngOnChanges(val:any){
    setTimeout(() => {
      if(this.model){
        let users:any[] = JSON.parse(val.model.currentValue);
        if(typeof users === "object" && !Array.isArray(users) && users !== null){
          this.selectedUsers = [users];
        }else{
          if(Array.isArray(users)){
            this.selectedUsers = users;
          }else{
            this.selectedUsers = [];
          }
        }
      }else{
        this.selectedUsers = [];
      }

      this.selectAzureUserEvent.emit(JSON.stringify(this.selectedUsers));
    }, 200);
   
  }

  ngOnInit(): void {

     // azure users
     this.azureUsers$ = this.searchText$.pipe(
      filter(user=> user !== null && user !== ''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMapWithLoading(user=>
         this.commonService.getAzureUsers(user)
        )
    );
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(user: string) {
    this.searchText$.next(user);
  }

  onClearSearch(){
      this.searchValue = '';
      this.searchText$.next('');
  }

  onClose(){
    this.searchValue = '';
    this.searchText$.next('');
  }

  onClickUser(e:any):void{

    console.log("value",e);
    console.log(this.selectedUsers);


    let index = this.selectedUsers.findIndex((user:any)=>user.id === e.id );

    if(index < 0){
      this.selectedUsers.push(e);
      this.selectAzureUserEvent.emit(JSON.stringify(this.selectedUsers));
    }
  }

  onRemove(user:any):void{
    this.selectedUsers = this.selectedUsers.filter((val:any) =>val !== user);
    this.selectAzureUserEvent.emit(JSON.stringify(this.selectedUsers));
  }

  onRemoveAll():void{
    this.selectedUsers = [];
  }

  menuOpen: boolean = false;
  menuBtnClick: boolean = false;
  expanded:boolean = false;
  // body = document.querySelector('#f-id') as HTMLInputElement | any;


  toggleMenu(event:any) {
    // console.log(event.offsetWidth);


    this.menuOpen = this.menuOpen?false:true;
    if(this.menuOpen){
      this.expanded = true;
      this.searchValue = '';
      this.searchText$.next('');

      if(this.appendTo=='body'){
        let azureRect:any = this.azureElem.nativeElement.getBoundingClientRect();
        this.searchStyle = `z-index: 1001;transform-origin: center top;top: ${azureRect.top+azureRect.height}px;position: fixed;width: ${azureRect.width}px;`
      }


    }else{
      this.expanded = false;
    }
  }
  preventCloseOnClick() {
    this.menuBtnClick = true;
  }

}
