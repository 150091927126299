import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, SharedModule } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { CommonService } from 'src/app/services/common.service';
import { ProjectService } from 'src/app/services/general/project.service';
import { GlobalService } from 'src/app/services/global.service';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
    selector: 'app-assign-user',
    templateUrl: './assign-user.component.html',
    styleUrls: ['./assign-user.component.scss'],
    standalone: true,
    imports: [ConfirmDialogModule, OverlayPanelModule, SharedModule, TableModule, NgIf, FormsModule, InputTextModule, ButtonModule, RippleModule, TooltipModule, NgFor, CheckboxModule, TitleCasePipe]
})
export class AssignUserComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private projectService: ProjectService,
    private globalService: GlobalService,
    private confirmationService: ConfirmationService,) { }

  availableUser:any = [];
  _tempAssignedUsers: any = [];
  assignedUsers:any = [];
  table_height:number = 0;
  totalRecords: number = 0;
  loading: boolean = false;
  selectedUsers:any = [];
  defaultAccess:any =[];
  projects:any =[];

  selectedAccess:any=[];

  _tempSelectedUsers: any = [];
  @ViewChild('usertable')
  usertable!: Table;

  @Input() project: number = 0;
  @Input() module: number = 0;
  @Input() useraccess:any=[{ 'action': 'read', 'default': true },{ 'action': 'write', 'default': true },{ 'action': 'delete', 'default': true }];
  @Input() department: any = [];

  user:any =[];

  ngOnInit(): void {

    this.commonService.loadCommon('projects');

    this.onResize();
    this.getAssignUsers();
    
    this.useraccess.forEach((el:any)=>{
      if(el.default){
        this.defaultAccess.push(el['action']);
      }
    });

    //load users

    this.commonService.loadCommon('users', (res: any, data: any) => {
      data.forEach(async (user: any) => {
        if (!user.isAssignedCheck == true) {
          this.availableUser.push(user);
        }
      })
    });


    this.commonService.project_change.subscribe((id: any) => {
      this.project = id;
      // let _departmentid = this.commonService.projectDetails('department_id',id);
      // let _departmentid= projectDetails;
      this.getAssignUsers();
      // this.getDepartmentUsers(_departmentid);
    });
  }

  getDepartmentUsers(departmentId:number = 0){
    this.commonService.getDepartmentUsers(departmentId).subscribe(res => {
      let result: any = res;
      this.availableUser = result.data;
    });
  }

  removeAssignedUser(userDetails:any){
    this.confirmationService.confirm({
      message: 'Are you sure that you want to deleted this item?',
      accept: async () => {
        this.projectService.removeProjectUser(this.project, this.module, userDetails.id).subscribe(
          res => {
            if (!res) { this.globalService.showError("Deletion Failed"); return; }
            this.globalService.showSuccess("Data successfully deleted");
            this.globalService.removeObjectData(this.assignedUsers,userDetails);
            this.globalService.removeObjectData(this.selectedUsers, userDetails);
          },
          err => {
            console.log(err);
          }
        )
      }
    });
  }

  getAssignUsers(){
    this.assignedUsers = [];
    this.selectedUsers = [];
    this._tempSelectedUsers=[];
    this.loading = true;
    this.projectService.getProjectUsers(this.project,this.module).subscribe(
      res=>{
      
          let _res: any = res;
          this.totalRecords = _res.length;
          _res.forEach((user: any) => {
            user.access = JSON.parse(user.access);
            this.assignedUsers.push(user);
            this.selectedUsers.push(user);
          })
          setTimeout(() => {
            this.loading = false;
            this.refreshTable();
          },500)
      }, 
      err=>{
        console.log(err);
      }
    );
  }

  refreshTable(){
    let main_table: any = this.usertable;
    main_table.clear();
    main_table.refreshTable();
  }

  //loop the selected users and format it for checking them
  saveAssignedUsers(overlayPanel:any =''){
    let users:any = [];
    let counter = 0;
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((user: any) => {
        let access = user.access != undefined ? JSON.stringify(user.access) : JSON.stringify(this.defaultAccess);
        users.push({ user_id: user.id, module_id: this.module, default_access: access, is_deleted: false });
        this._tempSelectedUsers.push({ user_id: user.id, module_id: this.module });
        counter++;
        if (this.selectedUsers.length == counter) {
          this.pushDeletedUsers(users, overlayPanel);
        }
      })
    }else{
      this.globalService.showWarn("Please select users to be assigned");
    }
  }

  //loop the assigned users and push the user object and tagged it as is deletedialog
  pushDeletedUsers(users:any,overlayPanel:any){
    let counter = 0;
    if (this.assignedUsers.length > 0){
      this.assignedUsers.forEach(async (user: any) => {
        let _u = { user_id: user.id, module_id: user.module_id };
        let inArray: boolean = await this.commonService.inMultiArrayData(this._tempSelectedUsers, _u );
        if (!inArray) {
         
          //check if the old assigned users are not in the selected user.  tagged it as is_deleted
          users.push({ user_id: user.id, module_id: user.module_id, default_access: JSON.stringify(this.defaultAccess), is_deleted: true });
        }

        counter++;

        if (this.assignedUsers.length == counter) {
          this.saveAssignedusers(users, overlayPanel);
        }
      });
    }else{
      this.saveAssignedusers(users, overlayPanel);
    }
  }

  //saved the users
  saveAssignedusers(users: any, overlayPanel:any){
    this.projectService.saveProjectUsers(this.project, users).subscribe(
      res => {
        if (res) {
          this.globalService.showSuccess("User successfully saved");
          this.getAssignUsers();
          overlayPanel.hide();
          return;
        }
        this.globalService.showError("Something went wrong");
      },
      err => {
        this.globalService.showError("Something went wrong");
      });
  }

  onResize(event?: any) {
    this.table_height = window.innerHeight - 300;
  }

  accessChanged(e:any,model:any) {
    let access:string = JSON.stringify(model.access);
    this.projectService.saveUserAccess(this.project,this.module,model.id,access).subscribe(
      res=>{
        if(res){
          this.globalService.showSuccess("Access successfully saved");
          return;
        }

        this.globalService.showError("Something went wrong");
        return;
      },
      err=>{
        this.globalService.showError("Something went wrong");
      }
    );
  }

}
