import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';



@Injectable({
  providedIn: 'root'
})
export class FormWizardService {
  show_errors: boolean = false;
  loader: boolean = false;
  saving: boolean = false;
  data: any = {}
  field: any = {}
  form_data: any = {}
  error_data: any = {}
  form_fields: any = []
  references: any = []
  constructor(private http: HttpClient, private config: ConfigService) { }
  readonly baseURL = this.config.getConfig().apiurl;


  showError(){
    this.show_errors = true;
    setTimeout(() => {
      this.clearErrors();
    }, 3000);
  }

  setField(field:any = []){
    this.field = {};
    for(let d of field){
      d.error = '';
      this.field[d.Id] = d;
    }
  }
  setData(data:any = {}){
    this.form_data = data;
  }

  updateOption(data:any={}){

    for(let d in data){
      if(this.field[d]){
        this.field[d].Options = data[d];
      }
      this.references[d] = data[d];
    }

  }

  getOptions(f:any = {}){
    // console.log(f)
    if(f.reference){
      return (this.references[f.reference]) ? this.references[f.reference] : f.Options;
    }
    return [];
  }

  checkError(){
    var r: boolean = false;
    for(let d in this.field){

     
      var req: boolean = (this.field[d].Required) ? this.field[d].Required : false; 
      var val: boolean = false;
      var visible: boolean = (this.field[d].visible) && this.field[d].visible != false ?true : false;
      // console.log("checker"+this.field[d].Type,this.form_data[d]);
      if(this.field[d].Type =='azure-user-multiple' && this.form_data[d] && this.form_data[d] != null){
        let _data = JSON.parse(this.form_data[d]);
        let _val:any =[];
        if(typeof _data === "object" && !Array.isArray(_data) && _data !== null){
         _val = [_data];
        }else{
          if(Array.isArray(_data)){
            _val = _data
          }else{
            _val = [];
          }
        }

        val = (this.form_data[d]) && _val.length > 0 ? this.form_data[d] : ''; 
      }else if(this.field[d].Type =='date' && this.form_data[d] && this.form_data[d] != null){
        val = (this.form_data[d]) && (this.form_data[d]) != "0000-00-00" ? this.form_data[d] : ''; 
      }
      else{
        val = (this.form_data[d]) ? this.form_data[d] : ''; 
      }
      if(req && !val && visible){
        this.error_data[d] = 'This field is required.';
        r = true;
      }    
    }
    if(r){
      this.showError();
    }

    return r;

  }

  setErrors(data: any = {}){
    var r : boolean = false;
    for(let d in data){
      if(this.field[d]){
        this.error_data[d] = data[d];
        r = true;
      }
    }
    if(r){
      this.showError();
    }


  }
  clearErrors(){
  
    this.show_errors = false;
    this.error_data = {};
  }

  getData(url: string){
    return this.http.get(this.baseURL + url, {});
  }

  getDataById(id: number, url: string) {
    return this.http.get(this.baseURL + url + id, {});
  }

  saveForm(data: any[], id: number = 0, url: string) {
    if (id > 0) {
      return this.http.put(this.baseURL + url + id, { data });
    }

    return this.http.post(this.baseURL + url, { data });
  }

  deleteData(id: number = 0, url: string) {
    return this.http.delete(this.baseURL + url + id, {});
  }



}
