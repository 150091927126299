import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';



@Injectable()
export class ModuleService {
    assigned_module: any = [];
    available_module: any = [];

  constructor(private http: HttpClient, private config: ConfigService) {
   
  }

  readonly baseURL = this.config.getConfig().apiurl + 'modules';
  getModuleDetails(id:any){
    return this.http.get(this.baseURL + "/" + id , {});
  }

  saveModule(data: any, id: any){
    if(id > 0){
      //update
      return this.http.put(this.baseURL + "/" + id , { data });
    }

    return this.http.post(this.baseURL+"/", { data });
  }

  deleteModule(id:any){
    return this.http.delete(this.baseURL + "/" + id , { });
  }

  //module reference Assignment

  getModuleReferences(moduleId:any){
    return this.http.get(this.baseURL + "/" + moduleId + '/references', { });
  }

  deleteModuleReference(moduleId:any,rowId:any){
    return this.http.delete(this.baseURL + "/" + moduleId + '/references/' + rowId, {  });
  }

  saveModuleReference(moduleId:any,data:any){
    return this.http.put(this.baseURL + "/" + moduleId + '/references', { data });
  }
  getAccountAccess(data:any){
    return this.http.post(this.baseURL + "/account/get", data);
  }
  saveAccountAccess(data:any){
    return this.http.post(this.baseURL + "/account", data);
  }
  
}
