import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomCookieService {
  constructor() {
    
  }

  get(cname: string) {
    var name = environment.env+'_'+cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";

  }

  set(name: string, value: string, expireDays: number = 3, secure:boolean = true, domain: string = this.setDomain(), path: string = '/') {
    name = environment.env+'_'+name;
    let d:Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;
    let cpath:string = path ? `; path=${path}` : '';
    let cdomain:string = domain ? `; domain=.${domain}` : '';
    let csecure:string = secure ? `;secure` : '';
    document.cookie = `${name}=${value}${csecure}; ${expires}${cpath}${cdomain}`;
  }

  clear(name:string){
    // name = environment.env+'_'+name;
    this.set(name, '', -1);
  }

  setExpirationDate(){
    const expDate: Date = new Date();
    return expDate.setHours(expDate.getHours() + 72);
  }

  setDomain(){
    let domain = environment.hostname;
    return domain;
  }
  
  logout(){
    // console.log('logout here');
    this.clear('token');
    this.clear('module_account_type');
    this.clear('ship_access');
    this.clear('module_access');
    this.clear('user_profile');
    this.clear('usrid');
    this.clear('refresh_token');
    this.clear('enc');
    this.clear('temp_user_data');
  }
}
