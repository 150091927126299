<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="grid" #azureElem>
    <div class="col-12 pb-0">
        <div *ngIf="disabled; then isDisabled; else notDisabled"></div>

        <ng-template #isDisabled>
            <span class="isDisabled selected-container {{viewType =='form'?'h-for-form':'h-for-table'}}" 
           >
                <div class="selected-details-badge">
                    <label class=" f-13"> {{selectedUser.displayName}}</label>
                </div>
                <span class="remove-all"  *ngIf="!noData"><i class="pi pi-times"></i></span>
                <span class="caret-down-placeholder"><i class="pi pi-chevron-down"></i></span>
            </span>
        </ng-template>
        <ng-template #notDisabled>
            <span class="selected-container {{viewType =='form'?'h-for-form':'h-for-table'}}" 
            (click)="toggleMenu();preventCloseOnClick()">
                <div class="selected-details-badge">
                    <label class=" f-13"> {{selectedUser.displayName}}</label>
                </div>
                <span class="remove-all" (click)="onRemoveValue()" *ngIf="!noData"><i class="pi pi-times"></i></span>
                <span class="caret-down-placeholder"><i class="pi pi-chevron-down"></i></span>
            </span>
        </ng-template>

    </div>
    <div class="col-12 pt-0 pb-0" *ngIf="menuOpen" #menuDropdownEl>
        <div class="azure-div {{viewType =='form'?'p-relative':''}}" style="{{searchStyle}}">
            <div class="list-cont {{viewType =='form'?'w-100-per':'w-max-cont'}}">
                <span class="p-input-icon-left w-100">
                    <i class="pi pi-search"></i>
                    <input type="text" placeholder="Search name or email here..." [(ngModel)]="searchValue"
                        (keyup)="search(getValue($event))" class="search-input-azure" #searchEl autofocus/>
                    <span *ngIf="searchValue" style="cursor:pointer;position: absolute;right: 10px;top: 10px;"
                        (click)="onClearSearch()" title="Clear">
                        <i class="pi pi-times"></i>
                    </span>
                </span>
                <div *ngIf="azureUsers$ | async as result" class="mt-2">
                    <div *ngIf="result.loading">
                        <div style="width: 100%; margin-bottom: 5px;text-align:center">
                            <div class="p-d-flex p-1 pl-2 pr-2">
                                <span style="font-size:10px;"><i class="pi pi-spin pi-spinner"></i> Searching
                                    {{searchText$ |
                                    async}} ...</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="result.data as users">
                        <div *ngIf="users.length > 0" style="width:100%">
                            <ul style="max-height: 200px; overflow-y: auto;">
                                <li *ngFor="let user of users" (click)="onClickUser(user)">
                                    <div class="grid m-0 suggestion-box">
                                        <div class="col p-0">
                                            <div class="p-d-flex p-2  p-flex-wrap">
                                                <div class="name">
                                                    <span>{{user.displayName}}</span>
                                                </div>
                                                <div class="mail">
                                                    <span>{{user.mail}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div *ngIf="result.error as error">{{error.message}}</div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div *ngIf="viewType == 'form'; else forTable"  > -->
<!-- <div [class]="col_class">
        <span class="p-input-icon-left w-100">
            <i class="pi pi-search"></i>
            <input type="text" placeholder="Search name or email here..." [(ngModel)]="searchValue" (keyup)="search(getValue($event))"
                class="search-input-azure" />
                <span *ngIf="searchValue" style="cursor:pointer;position: absolute;right: 10px;top: 10px;" (click)="onRemoveValue()" title="Remove user">
                    <i class="pi pi-times"></i>
                </span>
        </span>
        
       
        <div *ngIf="azureUsers$ | async as result" class="mt-2" id="search-result-data" >
            <div *ngIf="result.loading">
                <p-card [style]="{'width': '100%', 'margin-bottom': '5px','text-align':'center'}" >
                    <div class="p-d-flex p-1 pl-2 pr-2">
                        <span class="f-12"><i class="pi pi-spin pi-spinner"></i> Searching {{searchText$ | async}} ...</span>
                    </div>
                </p-card>
            </div>
            <div *ngIf="result.data as users">
                <p-card *ngIf="users.length > 0" [style]="{'width': '100%'}" >
                    <button class="btn-close" (click)="onClose()"><i class="pi pi-times"></i></button>
                    <ul style="max-height: 200px; overflow-y: auto;">
                        <li *ngFor="let user of users" (click)="onClickUser(user)">
                            <div class="grid m-0 suggestion-box">
                                <div class="col p-0">
                                    <div class="p-d-flex p-2  p-flex-wrap">
                                        <div class="name">
                                            <span>{{user.displayName}}</span>
                                        </div>
                                        <div class="mail">
                                            <span>{{user.mail}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </p-card>
            </div>
            <div *ngIf="result.error as error">{{error.message}}</div>
        </div>
    </div> -->


<!-- </div> -->


<!-- <ng-template #forTable>
    <div class="azure-div-for-table">
        <span class="p-input-icon-left w-100">
            <i class="pi pi-search"></i>
            <input type="text" placeholder="Search name or email here..." [(ngModel)]="searchValue" (keyup)="search(getValue($event))" style="font-size: 12px !important;"
                class="search-input-azure" />
                <span *ngIf="searchValue" style="cursor:pointer;position: absolute;right: 10px;top: 10px;" (click)="onRemoveValue()" title="Remove user">
                    <i class="pi pi-times"></i>
                </span>
        </span>
        
       
        <div *ngIf="azureUsers$ | async as result" class="mt-2" id="search-result-data" style="max-width:max-content;min-width: fit-content;width: auto;">
            <div *ngIf="result.loading">
                <p-card [style]="{'width': '100%', 'margin-bottom': '5px','text-align':'center'}" >
                    <div class="p-d-flex p-1 pl-2 pr-2">
                        <span class="f-12"><i class="pi pi-spin pi-spinner"></i> Searching {{searchText$ | async}} ...</span>
                    </div>
                </p-card>
            </div>
            <div *ngIf="result.data as users">
                <p-card *ngIf="users.length > 0"  >
                    <button class="btn-close" (click)="onClose()"><i class="pi pi-times"></i></button>
                    <ul style="max-height: 200px; overflow-y: auto;">
                        <li *ngFor="let user of users" (click)="onClickUser(user)">
                            <div class="grid m-0 suggestion-box">
                                <div class="col p-0">
                                    <div class="p-d-flex p-2  p-flex-wrap">
                                        <div class="name">
                                            <span>{{user.displayName}}</span>
                                        </div>
                                        <div class="mail">
                                            <span>{{user.mail}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </p-card>
            </div>
            <div *ngIf="result.error as error">{{error.message}}</div>
        </div>
    </div>
</ng-template> -->