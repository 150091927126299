import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  assigned_reference: any = [];

  constructor(private http: HttpClient, private config: ConfigService) {

  }

  readonly baseURL = this.config.getConfig().apiurl + 'references/master-list';
  readonly baseURLReference = this.config.getConfig().apiurl + 'references';

  getReferenceList(){
    return this.http.get(this.baseURL, {});
  }

  getReferenceValues(id:number) {
    return this.http.get(this.baseURL+"/" + id +'/values', {});
  }

  getReferenceColumns(id: number){
    return this.http.get(this.baseURL + "/" + id + '/columns', {});
  }

  saveReferenceValue(data: any, id: any, action: number = 0, group_id: any = ''){
    if (action == 0){
      return this.http.post(this.baseURL + "/" + id + '/values', { data });
    }else{
      return this.http.put(this.baseURL + "/" + id + '/values/' + group_id, { data });
    }
  }

  deleteReferenceValue(group_id: any, id: any, ) {
    return this.http.delete(this.baseURL + "/" + id + '/values/' + group_id, {  });
  }

  //reference master list CRUD

  saveMasterList(id:number = 0,data:any){
    let fields = data['fields'];
    let reference_name = data['reference_name'];
    let reference_code = data['reference_code'];
    if(id > 0){
      //update
      return this.http.put(this.baseURL + "/" + id, { fields, reference_name, reference_code });
    }else{
      //add
      return this.http.post(this.baseURL, { fields, reference_name, reference_code });
    }
  }

  deleteMasterList(id: number = 0){
    return this.http.delete(this.baseURL + "/" + id , { });
  }

  //assigning reference per projects
  assignReference(projectId: number = 0, masterListId: number = 0, data: any = [],moduleId: number){
    return this.http.post(this.baseURLReference + "/" + masterListId + '/assign/' + projectId + '/module/' + moduleId, { data});
  }

  getAssignedReference(projectId: number = 0, masterListId: number = 0, moduleId: number) {
    return this.http.get(this.baseURLReference + "/" + masterListId + '/assigned/' + projectId + '/module/' + moduleId, {});
  }

  removeAssignedReference(projectId: number = 0, masterListId: number = 0, groupId: number = 0, moduleId: number){
    return this.http.delete(this.baseURLReference + "/" + masterListId + '/projects/' + projectId + '/groups/' + groupId + '/module/' + moduleId, {});
  }
}
