<ng-container *ngIf="ready">
    <div class="">
    
            <div class="mb-3 pull-left col-sm-12">
                <div class="project-selection"
                appendTo="body"
                pTooltip="Set as Default"
                tooltipPosition="bottom"
                >
                    <p-dropdown
                            [options]="template_data"
                            [itemSize]="30"
                            placeholder="Select a Template"
                            [filter]="true"
                            filterBy="template_name"
                            optionLabel="template_name"
                            autoWidth="false"
                            styleClass="pull-left"
                            [(ngModel)]="selected_template"
                            [showClear]="true"
                            (onChange)="seemoreChange()"
                            [style]="{'width':'100%'}">
    
    
                            <ng-template pTemplate="selectedItem">
                                <div  *ngIf="selected_template">
                                     <b>TEMPLATE:</b> {{selected_template.template_name}}
                                </div>
                            </ng-template>
    
    
                            <ng-template let-item pTemplate="item">
                                    <div>
                                        <!-- <i class="fa fa-star-o" *ngIf="item.default"></i> -->
                                        <i class="fa fa-check" *ngIf="item.default"></i>
                                        <i class="fa fa-minus" *ngIf="!item.default"></i>
                                        <!-- <small *ngIf="item.default" style="font-size: 12px;">Default: </small> -->
                                        {{item.template_name}}
    
                                    </div>
                            </ng-template>
    
    
                    </p-dropdown>
             </div>
    
                    <button pButton type="button" label="Set as Default"  class="ml-2 p-button-sm pull-left" [disabled]="!selected_template" *ngIf="!selected_template.default" (click)="setDefault()"></button>
                    <button pButton type="button" label="Default" icon="fa fa-check" class="ml-2 p-button-sm pull-left" [disabled]="true" *ngIf="selected_template.default"></button>
    
                <button pButton
                    #editseemore
                    type="button"
                    icon="fa fa-pencil-square-o"
                    class="ml-2 p-button-sm pull-left p-button-secondary" [disabled]="!selected_template"
                    (click)="showCreate(selected_template)"
                    [appendTo]="editseemore"
                    pTooltip="Edit Template"
                    tooltipPosition="bottom"
                >
            </button>
                <button pButton type="button" icon="fa fa-trash" class="ml-2 p-button-sm pull-left p-button-danger" [disabled]="!selected_template"
                #removeseemore
                appendTo="body"
                pTooltip="Remove Template"
                [appendTo]="removeseemore"
                tooltipPosition="bottom"
                (click)="delete(selected_template)"
                ></button>
            </div>
    
    <p-divider></p-divider>
            <div class="grid mt-3 form-content p-2 row ui-dialog-body">
    
    
    
                <ng-container  *ngFor="let f of dialogconfig.data.fields">
                    <div class="form-group field pointer" [ngClass]="{'col-sm-6': (dialogconfig.data.fields.length>10), 'col-sm-12': (dialogconfig.data.fields.length<10), 'col-sm-4': (dialogconfig.data.fields.length>30)}">
                            <p-checkbox
                                name=""
                                [value]="f.Label"
                                [label]="f.Label"
                                [ngModel]="inData(f.Id)"
                                labelStyleClass="font-bold pointer disable-highlight"
                                [binary]="true"
                                (click)="toggleCheck($event.target, f.Id)"
                                [inputId]="f.Id">
                            </p-checkbox>
                    </div>
                </ng-container>
    
            </div>
    
    
    </div>
    
    <p-divider></p-divider>
    
    
    
    
    <div class="ui-dialog-footer  footer">
    
        <p-button icon="pi pi-plus" label="Create Template" (click)="showCreate()" styleClass="p-button-text p-button-sm pull-left"></p-button>
    
        <div class="pull-right">
            <p-button icon="pi pi-save" label="Apply & Save" (click)="updateCheckbox()" styleClass="p-button-text p-button-sm"></p-button>
            <p-button icon="pi pi-check" label="Apply" (click)="apply()" styleClass="p-button-text p-button-sm"></p-button>
            <p-button icon="pi pi-times" label="Close" (click)="close()" styleClass="p-button-text p-button-sm"></p-button>
        </div>
    
      </div>
    
    
    
    
    
    
      <!-- create template -->
      <p-dialog
        header="{{ (data_template.id) ? 'Edit Template' : 'Create Template'  }}"
        [(visible)]="show_create"
        [modal]="true"
        [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
        [style]="{width: '25vw'}"
      >
    
    
      <div class="p-field p-col col-sm-12">
        <label for="template_name">Template Name : </label>
        <input id="template_name" type="text" pInputText  [(ngModel)]="template_name" class="col-sm-12"/>
    </div>
    
    
    <p-divider></p-divider>
    <div class="ui-dialog-footer footer">
    
        <div class="pull-right">
            <p-button icon="pi pi-save" label="{{ (data_template.id) ? 'Update' : 'Add'  }}" (click)="saveTemplate()" styleClass="p-button-text p-button-sm"></p-button>
            <p-button icon="pi pi-times" label="Close" (click)="show_create=false" styleClass="p-button-text p-button-sm"></p-button>
        </div>
    
      </div>
    
    
    </p-dialog>
    
    
    <p-confirmDialog [style]="{width: '300px'}" key="deleteseemore" [baseZIndex]="10000" id="deleteseemore" #lo>
    
    
    
        <p-footer>
            <button pButton type="button" (click)="lo.accept()" label="Yes" icon="pi pi-check" class=" p-button-text pull-right"></button>
            <button pButton type="button" (click)="lo.reject()" label="No" icon="pi pi-times" class=" p-button-text pull-right"></button>
        </p-footer>
    
    </p-confirmDialog>
</ng-container>