import { Component, OnInit,ViewChild, ElementRef, OnDestroy  } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';

import { ConfigService } from 'src/app/services/config.service';

import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'src/app/services/global.service';
import { SocketService } from 'src/app/services/socket.service';

import * as moment from 'moment';
import { SharedModule } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { NgIf, NgClass, NgTemplateOutlet, NgFor } from '@angular/common';





@Component({
    selector: 'i95-app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    standalone: true,
    imports: [NgIf, AvatarModule, NgClass, ButtonModule, RippleModule, TooltipModule, FormsModule, InputTextModule, NgTemplateOutlet, NgFor, MultiSelectModule, SharedModule]
})
export class ChatComponent implements OnInit {
  message: any = '';
  search: any = '';
  selected: any =[];
  user_no_result: boolean = false;
  show_new_filter: boolean = false;
  options: any[] = [];
  chat_summary: any = [];
  online_users: any = [];
  chat_messages: any = [];
  chat_loader:boolean = false;
  chat_summary_loader:boolean = false;
  open_chat:boolean = false;
  current_session: any = 'USR'+this.config.userid+'RAND'+Math.floor(Math.random() * 1000000);

  chat_session: any;

  
  @ViewChild('chatnewopen', { static: true })
  chatnewopen!: ElementRef;
  
  @ViewChild('chatMessage', { static: true })
  chatMessage!: ElementRef;
  
  @ViewChild('chatbody', { static: true })
  chatbody!: ElementRef;

  constructor(
    public commonservice: CommonService,
    public userservice: UserService,
    public globalservice: GlobalService,
    public config: ConfigService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private http: HttpClient,
    private socketservice : SocketService,
    public dialogconfig: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    
    // this.options.push({
    //   label: 'Groups',
    //   child: []
    // });
    
    this.commonservice.openchat = true;

    this.commonservice.loadCommon('users',(err: any, result: any) => {
      // console.log(result);
        let users:any = [];
        for(let u of result) {
          u.filter = u.full_name+' '+u.email;
          users.push(u);
        }
        this.options.push({
          label: 'Users',
          child: users
        });
    });

    this.getSummary();
    this.getOnline();
    this.openSenderSocket();

    this.commonservice.loadCommon('account_types',(err: any, result: any) => {
      // console.log(this.commonservice.data.users);
      // this.account_types = this.commonservice.data.account_types;
    });


    this.commonservice.message_change.subscribe(next => {
     this.addSummaryList(next);
     this.addMessage(next);
    });

  }

  sync(){
    this.getSummary();
  }

  getOnline(){
        // get the online users
        this.config.getUserOnline().subscribe(users => {
          this.online_users = users;
        
        
              // fetch the changes in users online

              setTimeout(() => {

                this.socketservice.socket.on('new_online_indicator', (return_data: any) => {
                  var dataindex = this.online_users.indexOf(Number(return_data.usr));
                  if(return_data.status === 'online'){
                    if(dataindex<0){
                      this.online_users.push(Number(return_data.usr));
                    }
                  }else{
                    if(dataindex>=0){
                      this.online_users.splice(dataindex, 1);
                    }
                  }
                
                    //  console.log(this.online_users); 
                });
            
              },500);
        });



  }



  getSummary(start:number=0){
    this.chat_summary_loader = true;
    this.http.get(this.config.getConfig().apiurl+'chat/summary/'+start).subscribe((e:any)=>{
      // console.log(e);
      if(e.success){
        this.chat_summary = e.data;

        setTimeout(() => {
          if(this.dialogconfig.data.session_id){
            this.openChatSession({to:this.dialogconfig.data.session_id});
            this.dialogconfig.data.session_id = 0;
          }
        },300);
      }else{
        this.chat_summary = [];
        
      }
      
      this.chat_summary_loader = false;
    });
    this.openNewMessage();

    if(this.commonservice.is_mobile){
      this.open_chat = false;
    }
  }

  removeThis(i:any = 0){
    this.selected.splice(i, 1);
  }


  openNewMessage(){
    // let el: HTMLElement = this.chatnewopen.nativeElement;
    // el.click();
    this.chat_messages = []; 
    this.chat_session = false;
    this.open_chat = true;
    this.selected = [];
  }
  changeValue(e:any={}){
    console.log(e);
  }

  cancelChat(){
    // this.chat_session = false;
    this.open_chat = false;
  }

  send(){
    let to = [];

        if(this.selected.length > 0){
          for(let u of this.selected){  
            to.push(u.id);
          }
        }else{
          to.push(this.chat_session.id);
        }

          let send_data:any = {
            from: this.config.userid, 
            to: to,
            message: this.message,
            curren_session: this.current_session
          };
          
          this.chat_loader = true;
          this.http.post(this.config.getConfig().apiurl+'chat/sent', send_data).subscribe((e:any)=>{
            
            this.chat_loader = false;
            if(e.success){
              if(this.selected.length > 0){
                this.openChatSession(e.data[0]);
              }
              // setTimeout(() => {
                this.message = '';
                this.chatMessage.nativeElement.innerHTML = '';
                this.selected = [];
                this.scrollToBottom();
              // },300);

              // this.addSummaryList(e.data, 1);

            }
          });
    }

    onEnter(e:any){
      if(this.message==''){

      }else if(e.key=='Enter' && !e.shiftKey && this.message!=''){
        this.send();
      }
    }

  getChatMessages(start:any=0){

    let pass_data = {
      from: this.config.userid,
      to: this.chat_session.id,
      start: start
    };
    this.chat_messages = [];
    this.chat_loader = true;
    this.http.post(this.config.getConfig().apiurl+'chat/messages', pass_data).subscribe((e:any)=>{
      if(e.success){    
        for(let m of e.data) {
          
          this.chat_messages.unshift(m); 
        }
        
        this.scrollToBottom();
      }
      this.chat_loader = false;
    });

  }

  scrollToBottom() {
    setTimeout(() => {
      // console.log(this.chatbody.nativeElement);
      this.chatbody.nativeElement.scrollTop = this.chatbody.nativeElement.scrollHeight;
    },200); 
  }
  
  
  addSummaryList(data:any){

      data = JSON.parse(JSON.stringify(data));
        let origin = 0;
        if(data.from==this.config.userid){
          origin = 1;
          data.read = 1;
        }

        let data_index:any;
        data.origin = origin;
        if(!origin){
  
          let from = data.to;
          let to = data.from;
          data.from = from;
          data.to = to;
        }
        data_index = this.globalservice.getDataIndex(this.chat_summary,'to',data.to);

        if(data_index!= undefined){
          this.chat_summary.splice(data_index, 1);
          this.chat_summary.unshift(data);
        }else{
          this.chat_summary.unshift(data);
        }

  }
  
  addMessage(data:any){

    data = JSON.parse(JSON.stringify(data));

    if(data.from==this.chat_session.id || data.to==this.chat_session.id){
      this.chat_messages.push(data);
      this.scrollToBottom();
    }


  }


  openSenderSocket(){

    
    this.socketservice.socket.on('sender_message_'+this.config.userid, (return_data: any) => {
              this.addSummaryList(return_data);
              this.addMessage(return_data);
    });


  }


  momentDate(d:any=''){
    // console.log(d);
    let str = moment(d).calendar();
    // console.log(str); 
    return str; 
  }

  openChatSession(session:any){
    this.selected = [];
    session.read = 1;
    
    this.open_chat = true;
    let user_data:any = this.globalservice.getData(this.commonservice.data.users, 'id', session.to);
    if(user_data){
      this.chat_session = user_data;
      this.getChatMessages();
    }
  } 
  
  isOnline(userid:number = 0){  
    return this.online_users.includes(userid);

  }
    
  back(){
    this.ref.close();
  }

  ngOnDestroy(){
    // console.log("destroying child...")
    this.commonservice.openchat = false;
  }

}
