import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import {UserService } from '../user.service';
import {CommonService} from '../common.service';



@Injectable()
export class ProjectService {
  assigned_project: any = [];
  projectList:any=[];

  constructor(private http: HttpClient, private config: ConfigService,private userService: UserService,private commonService: CommonService) {
    
  }

  readonly baseURL = this.config.getConfig().apiurl+'projects';

  getProjects() {
    return this.http.get(this.baseURL, {});
  }

  getProjectById(id: number = 0) {
    return this.http.get(this.baseURL + '/' + id, {});
  }

  saveProject(data: any[], id: number = 0) {
    if (id > 0) {
      return this.http.put(this.baseURL + '/' + id, { data });
    }

    return this.http.post(this.baseURL + '/', { data });
  }

  deleteProject(id: number = 0) {
    return this.http.get(this.baseURL + '/' + id, {});
  }

  getProjectUsers(projectId: number = 0,moduleId:number = 0,ship_code:string=''){
    return this.http.get(this.baseURL + '/'+projectId+'/modules/'+moduleId+'/users?ship_code='+ship_code);
  }
  
  getAccessByUser(moduleId:number = 0, userid:any=''){

    let data:any = {
      module_id:moduleId,
      user_id: userid
    }
    let url = this.config.getConfig().apiurl+'useraccess';

    return this.http.post(url + '/byUser/',data);
  }
  
  //save project users
  //origin project

  saveProjectUsers(projectId:number = 0,data:any=[]){
    return this.http.post(this.baseURL + '/' + projectId + '/users',{data});
  }

  removeProjectUser(projectId:number=0,moduleId:number=0,userId:number =0,ship_code:string=''){
    return this.http.delete(this.baseURL + '/' + projectId + '/modules/' + moduleId + '/users/' + userId+'?ship_code='+ship_code, { });
  }

  saveUserAccess(projectId:number,moduleId:number,userId:number,data:any,ship_code:string = ''){
    return this.http.put(this.baseURL + '/' + projectId + '/modules/'+moduleId + '/users/'+userId+'/access?ship_code='+ship_code, {data});
  }

  allowAccess(access: any = ''): boolean {
    // console.log(this.commonservice.user_access);
    let ret: boolean = false;
    if (this.userService.user_profile.position >= 90) {
      ret = true;
    } else {
      ret =false;
      // console.log('access', ret);
      // console.log('access', this.commonservice.user_accesss, access);
    }
    return ret;
  }

}
