export const environment = {
  env:'prod',
  production: true,
  googleApi: 'AIzaSyDcsN-cdWsmEZDKEDul9sHeN6fQS7_LF4E',
  apiurl: 'https://api.rcgi95.com/',
  hostname: 'rcgi95.com',
  app: 'external',
  admin_url: 'https://admin.rcgi95.com',
  external_url: 'https://rcgi95.com',
  internal_url: 'https://int.rcgi95.com',
  socket_server:'https://socket.rcgi95.com',
  assets_url: 'https://cdn.rcgi95.com'
};
