
  <ng-template #blockloader>
    <div class="loader_body">
        <div class="loader-inner-body">
            <i class="pi pi-spin pi-spinner"></i>  Loading...
        </div>
    </div>
  </ng-template>


  

  <ng-template #userprofilepicture let-profile>

        <p-avatar
            [label]="commonservice.acronym(profile.user_data.first_name+' '+profile.user_data.last_name)"
            styleClass="p-mr-2"
            size="small"
            shape="circle"
            *ngIf="!profile.user_data.userphotofile"
            [style]="{'background-color': 'rgb(88 114 152)', 'color': '#ffffff'}"
        ></p-avatar>
        <p-avatar
            styleClass="p-mr-2"
            size="small"
            shape="circle"
            *ngIf="profile.user_data.userphotofile"
            [image]="profile.user_data.userphotofile"
            [style]="{'background-color': 'rgb(88 114 152)', 'color': '#ffffff'}"
        ></p-avatar>

        </ng-template>

        <p-card styleClass="i95-card-dialog">
            <ng-container *ngIf="loader">
                <p-skeleton width="100%" height="40px" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="100%" height="40px" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="100%" height="40px" styleClass="mb-2"></p-skeleton>
                <p-skeleton width="100%" height="40px" styleClass="mb-2"></p-skeleton>
            </ng-container>
            <ng-container *ngIf="!loader">
                
                <div *ngFor="let item of audit_trail">

                    <p-divider align="center" styleClass="mt-0">
                       {{momentDate(item.date)}}
                    </p-divider>

                        <ng-container  *ngFor="let item_data of item.data" >
                            <span class="trail-item" [innerHTML]="trailMessage(item_data)">
                            </span>
                        </ng-container>
                    <br>

                </div>

            </ng-container>
        </p-card>
        