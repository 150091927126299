import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client'
import { map, delay } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { UserService } from './user.service';
import { GlobalService } from './global.service';
import { environment } from 'src/environments/environment';



@Injectable()
export class SocketService {
  private userid: any = 0;
  public socket: any;
  private connected: boolean = false;
  private conn: any;
  public socket_server:string =  '';
  
  constructor(
    public configservice: ConfigService,
    public userservice: UserService,
    public globalservice: GlobalService, 

  ) {

    // this.connectSocket();
    // alert(this.)
    let env:any = environment;
    this.socket_server = (env?.socket_server) ? env?.socket_server: '';

   }




   connectSocket(){

    // this.delay(3000);

    // this.resolveAfter2Seconds(20).then(value => {
    //   alert(value);
    // });

      if(this.configservice.userid && !this.connected){
        // setTimeout(() => {
          var q =  {query: 'new_user='+this.configservice.userid};
          var con: any = io;
          this.socket = con.io.connect(this.socket_server, q);
          this.connected = true;

          this.socket.on('update_'+this.configservice.userid, (return_data: any) => {

            if(return_data=='user_profile'){
              this.getUserProfile();
            }else if(return_data=='logout'){
              this.userservice.logout();
              this.globalservice.logout();
              this.destroySocket();
            }
          });
        // },100);
      }
   }


   getUserProfile(){
    this.userservice.loadUserprofile().subscribe(e => {
          
      let data = e;
      if(data.success){
        var enc = this.configservice.encrypt(JSON.stringify(data.data));
        localStorage.setItem('user_profile',enc);  
        this.userservice.getUserProfile();  

        if(!this.userservice.user_profile.active){
          this.globalservice.showError('Your Account has been deactivated. You will be logout.');
  
          setTimeout(() => {
              this.userservice.logout();
              this.destroySocket();
              this.globalservice.logout();
          }, 3000);
        }

      }
  
    },(error) => {        
      
    });
  }

  fetchData(tag: any = '', data: any = {}){
    return new Promise(resolve => {
      setTimeout(() => {
        // resolve(x);
        if(this.connected){
          var return_data: any = {};
          this.socket.on(tag, (return_data: any) => {
            resolve(return_data);
          });
        }

      }, 500);
    });
   }

   getOnlineUsers(){
     
   }

   destroySocket(){
    // console.log(this.socket);
    // this.socket.disconnect();
    this.connected = false;
   }



}
