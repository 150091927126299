import { Component, OnInit, Input, HostListener, ChangeDetectorRef, NgZone } from '@angular/core';
import { ConfirmationService, MessageService, ConfirmEventType, SharedModule } from "primeng/api";
import { ReferenceV2Service } from './referencev2.serivce';
import { GlobalService } from 'src/app/services/global.service';

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputNumberModule } from 'primeng/inputnumber';
import { NgFor, NgSwitch, NgSwitchCase, NgIf } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';

@Component({
    selector: 'app-referencev2',
    templateUrl: './referencev2.component.html',
    styleUrls: ['./referencev2.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, DialogModule, SharedModule, InputTextModule, InputSwitchModule, NgFor, NgSwitch, NgSwitchCase, InputNumberModule, ColorPickerModule, ButtonModule, ToastModule, ConfirmDialogModule, DropdownModule, NgIf, TooltipModule, TableModule]
})
export class Referencev2Component implements OnInit {

  @Input() reference_fields: any;
  @Input() project: number = 0;
  @Input() module: number = 0;

  // references
  reference: any = [];
  breadcrumbs: any = [];
  items: any = [];
  loading: boolean = false;
  totalRecords: number = 0;
  table_height: number = 0;
  visible: boolean = false;
  checked: boolean = true;
  ref_data: any = {};
  action: string = '';
  submitted: boolean = false;
  field_types: any = [
    { name: 'Default', value: 'default' },
    { name: 'User', value: 'user' },
    { name: 'Group', value: 'group' },
  ];
  active_reference: any = null;
  active_fieldtype: any = '';
  active_value_id: number = 0;
  active_parent_id: number = 0;
  project_change_obj:any;

  bc: any = [];

  referenceForm!: UntypedFormGroup;

  hasAdditional: boolean = false;
  level: number = 1;
  added_columns: any = [];
  default_fields: any = ['parent_id', 'project_id', 'module_id', 'field', 'type', 'active', 'value'];
  data_to_save: any = {};

  with_sub:boolean = false;


  bulk:any = {
    visible: false,
  }


  constructor(
    public referencev2service: ReferenceV2Service,
    public globalService: GlobalService,
    public commonservice: CommonService,
    private confirmationService: ConfirmationService, private messageService: MessageService,
    public formBuilder: UntypedFormBuilder,
  ) { }

  ngOnDestroy(){
    if(this.project){
      this.project_change_obj.unsubscribe();
    }
  }

  ngOnInit(): void {

    this.createFormControl();


    if(this.project){
      this.project_change_obj = this.commonservice.project_change.subscribe(next => {
        setTimeout(()=>{
          this.getReference();
        }, 100);
      });
    }


  }

  getItems() {
    this.referencev2service.getItems().subscribe((e: any) => {
      console.log(e);
    });
  }

  changeOrder(current_index:number, order:string){
    let current_data: any = JSON.parse(JSON.stringify(this.items[current_index]));
    let change_data:any
    let change_index:number = 0
    if(order=='up'){
      change_index = current_index-1;
    }else{
      change_index = current_index+1;
    }
    
    change_data = JSON.parse(JSON.stringify(this.items[change_index]));

    this.items[current_index] = change_data;
    this.items[change_index] = current_data;
    this.changes_identify = true;
  }

  changes_identify:boolean = false;
  saveChanges(){
    let item_ids:object[] = [];
    for(let itm of this.items){
      item_ids.push(itm.id);
    }

    // console.log(item_ids);
    this.changes_identify = false;


    this.referencev2service.save_order(item_ids).subscribe((e: any) => {
    }, (f: any) => {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: f.msg });
    });


  }


  // id_order:object[] = [];
  getReference() {
    this.level = 1;
    this.loading = true;
    this.bc = [];
    this.items = [];
    this.added_columns = [];

    if (this.active_reference != null) {
      this.with_sub = this.active_reference.with_sub != undefined ? this.active_reference.with_sub:false;
      let params: any = {
        ref: this.active_reference.field,
        project_id: this.project,
        module_id: this.module
      }

      this.bc.push({ label: this.active_reference.displayName, ref: true });

      this.referencev2service.getReference(params).subscribe((e: any) => {
        let data = e.data;


        // this.breadcrumbs.push(this.active_reference.displayName);
        data.forEach((ref: any) => {
          ref.active = ref.active == 1 ? true : false;

          if (ref.additional_data != '' && ref.additional_data != null && ref.additional_data != "''") {
            ref.additional_data = JSON.parse(ref.additional_data);
          }

          if (ref.parent_id == 0) {
            this.items.push(ref);
          }
        });

        // console.log('ref', this.items);

        this.reference = data;
        this.setLevel();
        this.loading = false;
      });
      return;
    }

    this.loading = false;
  }

  active_data: any = [];
  active_index: number = 0;
  showChild(data: any, from: string = 'table') {
    this.active_data = data;
    if (data['id'] != 0) {
      this.items = [];
    }

    (async () => {
      await this.reference.forEach((ref: any) => {
        if (ref.id == data['id']) {
          this.bc.push({ label: ref.value, id: ref.id, ref: false });
          this.active_parent_id = ref.id;
        }

        //show the reference value for the selected reference
        if (ref.parent_id == data['id']) {
          this.items.push(ref);
        }
      });

      this.setLevel(data);
    })();



  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    let reduce_h = 230;
    this.table_height = window.innerHeight - reduce_h;
  }

  navigateBc(data: any, index = 0) {
    if (index == (this.bc.length - 1)) {
      return;
    }

    if (data.ref) {
      this.active_parent_id = 0;
      this.getReference();
      this.level = 1;
      return;
    } else {
      this.showChild(data, 'nav');
      //check the index of the selected title
      let _indextitle = this.bc.findIndex((obj: any) => obj.id === data.id);
      let _count = (this.bc.length - (_indextitle + 1));
      this.bc.splice((_indextitle + 1), _count);

    }
  }

  noFunctionAlert() {
    this.globalService.showInfo("Sorry this function is still under development and will be available soon.");
  }

  delete(id: number) {

    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.referencev2service.delete(id).subscribe((e: any) => {
          this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: e.msg });
          setTimeout(e => {
            (async () => {
              let _index: number = await this.items.findIndex((a: any) => { return Number(a.id) === id });
              await (this.items).splice(_index, 1);

              let _index2: number = await this.reference.findIndex((a: any) => { return Number(a.id) === id });
              await (this.reference).splice(_index2, 1);
            }
            )();
          }, 200);
        }, (f: any) => {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: f.msg });
        });

      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
            break;
        }
      }
    });
  }

  deactivate(id: number = 0, active: Boolean = false) {
    let label: String = active ? 'Activate' : 'Deactivate';
    this.confirmationService.confirm({
      message: 'Do you want to ' + label.toLowerCase() + ' this reference?',
      header: label + ' Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.referencev2service.deactivate(id, { active: active }).subscribe((e: any) => {
          this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: e.msg });

        }, (f: any) => {
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: f.msg });
        });

      },
      reject: (type: any) => {
        setTimeout(e => {
          (async () => {
            let _index: any = await this.items.findIndex((a: any) => { return Number(a.id) === id });
            this.items[_index]['active'] = active ? false : true;
            console.log(this.items);
          }
          )();
        }, 200);
      }
    });
  }

  view(id: number = 0) {
    //clear and set to default all the values upon opening the modal
    this.data_to_save = {};
    //reset form control by recreating it
    this.createFormControl();
    //set additional field 
    this.setAdditionalField();

    //reset the form
    this.referenceForm.reset();
    this.visible = true;
    this.active_value_id = id;
    if (id != 0) {
      //show details
      this.referencev2service.getDetails(id).subscribe((e: any) => {
        setTimeout(() => {
          e.data.active = e.data.active ? true : false;

          // this.ref_data = e.data;
          let data = e.data;
          let _parse_data: any = {
            parent_id: data.parent_id,
            project_id: data.project_id,
            module_id: data.module_id,
            field: data.field,
            type: data.type,
            active: data.active,
            value: data.value
          };

        
          if (data.additional_data != "" && data.additional_data != null && data.additional_data != "''") {
            let _adata = JSON.parse(data.additional_data);
            let _keys = Object.keys(_adata);
            _keys.forEach((e: any) => {
              _parse_data[e] = _adata[e];
            });
          }

          this.referenceForm.patchValue(_parse_data)
        }, 200)
      })
    } else {

      let _default_data: any = {
        parent_id: this.active_parent_id,
        project_id: this.project,
        module_id: this.module,
        field: this.active_reference.field,
        type: 'default',
        active: false
      };

      this.reference_fields.forEach((field: any) => {
        /* validate if additional_fields is not empty and the selected reference  */
        if (field.additional_fields != undefined && field.additional_fields.length > 0 && field.field == this.active_reference.field) {
          //push the data in the added columns
          let _col = (field.additional_fields.find((obj: any) => obj.level === this.level));
          if (_col != undefined) {
            _col.data.forEach((col: any) => {
              //assign value to the dynamic additional_data
              _default_data[col.field] = col.value;
            });
          }
        }
      });

      this.referenceForm.patchValue(_default_data);

    }

  }
  view2(id: number = 0) {

    let newval:string = '';

    for(let i of this.items){
      newval +=i.value+'\n';
    }

    this.referenceForm.patchValue({value: newval})
    this.bulk.visible = true;
    this.active_value_id = id;

  }

  submitBulk(type:string=''){

    let new_data = this.referenceForm.value.value.split('\n');
    let save_data:any[] = []

    for(let d of new_data){
      if(d.trim())
        save_data.push(d);
    }

    let _default_data: any = {
      parent_id: this.active_parent_id,
      project_id: this.project,
      module_id: this.module,
      field: this.active_reference.field,
      type: 'default',
      active: false
    };

    let pass_data = {
      reference: save_data,
      default_data: _default_data,
      type: type
    }

    this.referencev2service.bulkSave(pass_data).subscribe((e)=>{
      if(e=='success'){
        this.globalService.showSuccess('Successfully updated.')
        this.getReference()
        this.bulk.visible = false
      }
    });


  }

  onSubmit() {
    this.referenceForm.value.active = this.referenceForm.value.active ? 1 : 0;
    /* set additional value before saving */
    this.setAdditionalFieldValue();
    this.referencev2service.save(this.data_to_save, this.active_value_id).subscribe((success: any) => {

      success.data.active = success.data.active == 1 ? true : false;
      if (success.data.additional_data != '' && success.data.additional_data != null) {
        success.data.additional_data = JSON.parse(success.data.additional_data);
      }

      this.messageService.add({ severity: 'success', summary: 'Success', detail: success.msg });
      if (this.active_value_id == 0) {
        this.items.push(success.data);
        this.reference.push(success.data);
      } else {
        (async () => {
          let _index: any = await this.items.findIndex((a: any) => { return Number(a.id) === Number(success.data.id) });


          this.items[_index] = success.data;

          let _index2: any = await this.reference.findIndex((a: any) => { return Number(a.id) === Number(success.data.id) });
          this.reference[_index2] = success.data;
        })();
      }
      this.referenceForm.reset();
      this.visible = false;
    }, (failed: any) => {

      this.messageService.add({ severity: 'error', summary: 'Failed', detail: failed.msg });
    });
  }

  /* new reference navigation */

  setLevel(data: any = {}) {
    if (Object.keys(data).length > 0) {
      /* set the level of the current breadcrumb */
      let _index = this.bc.findIndex((obj: any) => obj.id === data.id);
      this.level = (_index + 1);
    }



    /* set additional field */
    this.setAdditionalField();
  }

  /* description: create additional field
    check the reference_fields additional_data object
    if addtional_fields is not empty create the additional_column and create new form control based on the additional fields

  */
  setAdditionalField() {
    this.added_columns = [];
    this.reference_fields.forEach((field: any) => {
      /* validate if additional_fields is not empty and the selected reference  */
      if (field.additional_fields != undefined && field.additional_fields.length > 0 && field.field == this.active_reference.field) {
        //push the data in the added columns
        let _col = (field.additional_fields.find((obj: any) => obj.level === this.level));
        if (_col != undefined) {
          this.added_columns = _col.data;
          this.added_columns.forEach((col: any) => {
            //create new control for the dynamic field
            this.referenceForm.addControl(col.field, new UntypedFormControl(col.value));
          });
        }

      } else {
        /* delete the additional_data so tat it will not included in saving */
        if (this.data_to_save.additional_data != undefined) {
          delete this.data_to_save.additional_data;
        }

      }
    });

  }

  /* add value to the  addiotnal_data */
  setAdditionalFieldValue() {
    let keys = Object.keys(this.referenceForm.value);
    let additional_data: any = {};
    this.setValueToSave(this.referenceForm.value);
    keys.forEach((key: any) => {
      /* compare the default fields */
      if (!this.globalService.isInArray(key, this.default_fields)) {
        /* if not in array it means that it is generated dynamically then push to additional_data array for saving */
        additional_data[key] = this.referenceForm.value[key];
        /* then delete the object so that it will not be included in the array to save */
        delete this.data_to_save[key];
      }
    });

    /* if addtional data is not empty push additional data to data_to_Save for saving */
    if (Object.keys(additional_data).length > 0) {
      this.data_to_save['additional_data'] = additional_data;
    }
  }

  setValueToSave(data: any) {
    this.data_to_save = data;
  }

  /* create default form control field */
  createFormControl() {
    this.referenceForm = this.formBuilder.group({
      parent_id: new UntypedFormControl(0),
      project_id: new UntypedFormControl(this.project),
      module_id: new UntypedFormControl(this.module),
      field: new UntypedFormControl(''),
      type: new UntypedFormControl('default'),
      active: new UntypedFormControl(false),
      value: new UntypedFormControl('')
    });
  }

  undefinedNullChecker(value: any) {
    if (value != undefined) {
      return value;
    }
    return false;
  }

}
