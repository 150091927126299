import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpXsrfTokenExtractor, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, retry, tap } from "rxjs/operators";
import { ConfigService } from "../services/config.service";
import { GlobalService } from "../services/global.service";
import { CustomCookieService } from "../services/custome-cookie.service";
import * as moment from 'moment';



@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(
        private router: Router,
        private globalservice: GlobalService,
        public tokenExtractor: HttpXsrfTokenExtractor,
        private configservice: ConfigService,
        private cookieService: CustomCookieService

    ){
        
    }

    // |dash| = -
    // |singlequote| = '
    // |and| = &
    // |slash| = /
    // |backslash| = \

    replaceString(body:any = ''){    
        // console.log(body)
        let str:any = JSON.stringify(body);
        if(body instanceof FormData) return body;


        // let toreplace:any = {'dash':'-','singlequote':'\'','backslash':'\\','slash':'/','and':'&','equal':'='};
        let toreplace:any = {'singlequote':'\'','backslash':'\\','slash':'/','and':'&','equal':'='};
        for(let i in toreplace){
            str = str.replaceAll(toreplace[i], `|${i}|`);
            // console.log(i,str)
        }
        return JSON.parse(str);
    }
    

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        
        

        // replace all sql injection query
        // let new_body = this.replaceString(req.body);
        let new_body = req.body;
    

        req = req.clone({
            body: new_body
        });


        const cookieheaderName = 'XSRF-TOKEN';
        let csrfToken:any = this.tokenExtractor.getToken();
        
        if (csrfToken) {
            
            req = req.clone({ 
                    withCredentials: true,
                    headers: req.headers.set('XSRF-TOKEN', csrfToken) 
                });
        }else{
            // csrf token
            req = req.clone({
                withCredentials: true,
            });

        }

        req = req.clone({ 
            withCredentials: true,
            headers: req.headers.set('datetoday',moment().format('Y-MM-DD HH-mm:ss')) 
        });
        // req = req.clone({ 
        //     withCredentials: true,
        //     headers: req.headers.set('timetoday',moment().format('Y-MM-DD')) 
        // });

        // req = req.clone({ 
        //     headers: req.headers.set('withCredentials', 'true') 
        // });

        //const token = localStorage.getItem('token');
        const token = this.cookieService.get('token');
        // console.log(req);
        // req.body = this.globalservice.encrypt(req.body);
        var debug = (this.configservice.getConfig().debug) ? this.configservice.getConfig().debug : 'true';  
        // console.log(debug);

        var if_token = (this.configservice.getConfig().apiurl+'auth/token'==req.url);
        
        let forupload = (req.url==this.configservice.getConfig().apiurl+'upload' && req.method=='POST');
        
        if(req.body && debug=='false' && !if_token && !forupload){
            // alert();
            req = req.clone({
                body: {datastring: this.configservice.encrypt(req.body, true)}
            });
        }


        if(token!=null){
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.cookieService.get('token')}`
                }
            });
            return next.handle(req).pipe(

                // tap((httpEvent: HttpEvent<any>) =>{
                //     console.log("response: ", httpEvent);
    
                // }),
                // retry(1),
                catchError((error: HttpErrorResponse)=>{

   

                    if(error.status==403){

                        this.globalservice.gettoken();

                        // this.globalservice.showInfo('Your token has expired. You will be redirected to the login page.');
                        // localStorage.clear();

                        // setTimeout(() => {
                        //     this.router.navigateByUrl('/login');
                        // }, 2000);
                        // alert();

                    }else if(error.status==401){
                        this.cookieService.logout();
                        // alert();
                        localStorage.clear();
                        this.router.navigateByUrl('/login');
                    }

                    return throwError(error);
                    
                })
            );
        }
        else{
            return next.handle(req).pipe(tap(res=> {
                // console.log('s',res);  
                if (res instanceof HttpResponse) {         
                    
                    // console.log( 'test',res);                        
                }
            }));
        }

        


    }

}