import { Component, OnInit } from '@angular/core';
import {DynamicDialogRef, DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { GlobalService } from 'src/app/services/global.service';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { NgIf, NgFor, NgClass } from '@angular/common';
    




@Component({
    selector: 'i95-see-more',
    templateUrl: './see-more.component.html',
    styleUrls: ['./see-more.component.scss'],
    standalone: true,
    imports: [NgIf, TooltipModule, DropdownModule, FormsModule, SharedModule, ButtonModule, DividerModule, NgFor, NgClass, CheckboxModule, DialogModule, InputTextModule, ConfirmDialogModule]
})
export class SeeMoreComponent implements OnInit {

  selected_template: any = {};
  data_template: any = {};
  selected_fields: any = [];
  template_data: any = [];
  show_create: any = false;
  ready: any = false;
  template_name: any = '';
  module_code: any = '';

  constructor(
    public ref: DynamicDialogRef, 
    public dialogconfig: DynamicDialogConfig,
    private http: HttpClient,
    private config: ConfigService,
    public globalservice: GlobalService,
    public confirmationService: ConfirmationService,
  ) { 


  }

  ngOnInit(): void {
    // alert();
    this.getTemplates();


  }

  close(){
    this.ref.close();
  }

  getTemplates(){
    this.http.get<any>(this.config.getConfig().apiurl+'general/seemore/list/'+this.dialogconfig.data.module).subscribe(e => {
          
      let data:any = e;
      if(data.data){
        this.template_data = data.data;




        if(this.dialogconfig.data.see_more_data){
          var selected_template = (this.dialogconfig.data.see_more_data.selected_template) ? this.dialogconfig.data.see_more_data.selected_template : {};
          var selected_fields = (this.dialogconfig.data.see_more_data.fields) ? this.dialogconfig.data.see_more_data.fields : [];


          if(selected_template.id){
            var dindex:any = this.globalservice.getDataIndex(this.template_data, 'id', selected_template.id);
            

            this.selected_template = this.template_data[dindex];
            this.selected_fields = JSON.parse(JSON.stringify(selected_fields));
          }

        }

      }else{
        this.template_data = [];
      }

      setTimeout(()=>{
        this.ready = true
      }, 500);

    },(error) => {        
    
    });  


  }

  saveTemplate(){
    var save_data:any = {
      template_name:this.template_name, 
      module_code:this.dialogconfig.data.module
    };

    if(this.data_template.id){
      save_data.id = this.data_template.id;
    }


    this.http.post<any>(this.config.getConfig().apiurl+'general/seemore',save_data).subscribe(e => {
          
      let data:any = e;
      if(data.success){
        this.show_create=false;

        if(this.data_template.id){
          this.globalservice.showSuccess('Successfully Updated.');
          var dindex:any = this.globalservice.getDataIndex(this.template_data, 'id', this.data_template.id);
          this.template_data[dindex] = data.data;
          
          this.selected_template = this.template_data[dindex];
        }else{
          this.globalservice.showSuccess('Successfully Added.');
          this.template_data.push(data.data);
          this.selected_template = data.data;
        }

      }

    },(error) => {        
    
    });
  }

  seemoreChange(){

    console.log('selected_template',this.selected_template);

    if(this.selected_template==null){

      this.selected_fields = [];
    }else if(!this.selected_template.data){
      this.selected_fields = [];
    }else{
      this.selected_fields = JSON.parse(this.selected_template.data);
    }

  }

  setDefault(){
    var params_data:any = {
      module_code: this.dialogconfig.data.module
    };
    // save_data.data = JSON.stringify(this.selected_fields);

    this.http.put<any>(this.config.getConfig().apiurl+'general/seemore/default/'+this.selected_template.id,params_data).subscribe(e => {
          
      let data:any = e;
      if(data.success){
        this.show_create=false;

        if(this.selected_template.id){
          this.globalservice.showSuccess('Successfully Updated.');
          var dindex:any = this.globalservice.getDataIndex(this.template_data, 'id', this.selected_template.id);
          for(let t of this.template_data){
            t.default = 0;
          }
          this.template_data[dindex].default = 1;
        }

      }

    },(error) => {        
    
    });

  }

  updateCheckbox(){ 
    if(!this.selected_template.id){
      this.globalservice.showInfo('Please select/create a template');
    }else{
      var save_data:any = this.selected_template;
      // console.log('here',save_data);
      delete save_data.created_date;
      delete save_data.modified_date;
      save_data.data = JSON.stringify(this.selected_fields);
  
      this.http.put<any>(this.config.getConfig().apiurl+'general/seemore/'+this.selected_template.id,save_data).subscribe(e => {
            
        let data:any = e;
        if(data.success){
          this.show_create=false;
  
          if(this.selected_template.id){
            this.globalservice.showSuccess('Successfully Updated.');
            var dindex:any = this.globalservice.getDataIndex(this.template_data, 'id', this.selected_template.id);
            this.template_data[dindex] = data.data;
            this.apply();
          }
  
        }
  
      },(error) => {        
      
      });
  
    }


  }

  toggleCheck(check: any, field: any){
    if(this.inData(field)){
      if(!check.checked){
        var indx = this.selected_fields.indexOf(field);
        this.selected_fields.splice(indx, 1);
        return;
      }
     
      this.selected_fields.push(field);
    }else{
      this.selected_fields.push(field);
    }
    return false;
  }

  inData(field:any=''){
    var ret:any = this.selected_fields.includes(field);
    return ret;
  }

  delete(data:any){
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete this template?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'deleteseemore',
        accept: () => {
    
          this.http.delete<any>(this.config.getConfig().apiurl+'general/seemore/'+data.id).subscribe(e => {
            
            let data:any = e;

            if(data.success){
              
              this.globalservice.showSuccess('Successfully Deleted.');
              var dindex:any = this.globalservice.getDataIndex(this.template_data, 'id', data.id);
              this.selected_template = {};
              this.template_data.splice(dindex, 1);

            }
          
          },(error) => {        
          
          });  
    
        }
    });
  }
  apply(){
    this.ref.close({
      selected_template: this.selected_template,
      fields: this.selected_fields});
  }
  showCreate(data:any=false){

    if(data){
      this.data_template = data;
      this.template_name=data.template_name;
    }else{
      this.data_template = {};
      
    this.template_name='';
    }

    this.show_create = true;
  }

}
