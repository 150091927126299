
<p-confirmDialog [style]="{width: '300px'}" key="confirmdeletecomment" [baseZIndex]="10000" id="confirmdeletecomment" #deletefile>
    <p-footer>
        <button pButton type="button" (click)="deletefile.accept()" label="Yes" icon="pi pi-check" class=" p-button-text pull-right"></button>
        <button pButton type="button" (click)="deletefile.reject()" label="No" icon="pi pi-trash" class=" p-button-text pull-right"></button>
    </p-footer>
</p-confirmDialog>









<!-- [scrollTop]='commentcontainer.scrollHeight' -->
<div #commentcontainer class="msg-comment-container" (scroll)="onScroll($event)" style="position:relative;{{style}}">
    
    <div class="comment-loader" *ngIf="loader" >
        <div class="lds-loader" >
            <i class="fa fa-spin fa-spinner mr-2"></i>
            <span>Loading...</span>
        </div>
    </div>

  <ng-container>
      
      <div *ngFor="let c of comments_data;index as i" class="comment-content" [ngClass]="{'owner': configservice.userid==c.created_by}">

            @if(c.is_deleted == 1){
                <div class="comment-inner">
                    <p style="margin-bottom:0;font-size:.75rem;font-style: italic;">This comment has been deleted. <a *ngIf="configservice.userid==c.created_by" style="text-decoration: underline;color:blue;font-weight: 500;cursor: pointer;" (click)="onUndo(c)">Undo</a></p>
                </div>
            }
            @else{
                <div class="standard-user-dp">
                    <img [src]="commonservice.userProfilePicture(c.created_by)">
                </div>
                <div class="comment-inner">
                    <div class="comment-name">{{commonservice.userName(c.created_by)}}  <div class="comment-date"> {{ commonservice.convertDT(c.created_date, 'MM/DD/Y h:mm A') }} {{c.modified_date ? 'Edited':''}}</div></div>
                    <pre>{{c.comments}}</pre>
                    <!-- <textarea 
                      #editcommentText
                      pInputTextarea
                      class="inputfield w-full no-resize"
                      [(ngModel)]="c.comments"
                      placeholder="Add comment here... &#10;(Press enter to submit comment)"
                      (keydown)="onEnterEdit($event,c.id)"
                  ></textarea> -->
      
                      <!-- <button (click)="onUpdate(c)" class="btnedit btn btn-primary btn-sm" *ngIf="configservice.userid==c.created_by && i == (comments_data.length - 1)"><i class="fa fa-pencil"></i></button> -->
                      <!-- <button (click)="deleteComment(c)" class="btndel btn btn-outline-danger btn-sm" *ngIf="configservice.userid==c.created_by && i == (comments_data.length - 1)"><i class="fa fa-ellipsis-v"></i></button> -->
                       @if(module_code == 'ticket_trail'){
                        <button (click)="btnaction.toggle($event)" class="btndel btn btn-outline-secondary btn-sm pl-2 pr-2" *ngIf="configservice.userid==c.created_by && i == (comments_data.length - 1)" title="More options"><i class="fa fa-ellipsis-v"></i></button>
                       }@else{
                        <button (click)="btnaction.toggle($event)" class="btndel btn btn-outline-secondary btn-sm pl-2 pr-2" *ngIf="configservice.userid==c.created_by " title="More options"><i class="fa fa-ellipsis-v"></i></button>
                       }
                      
                      <p-overlayPanel #btnaction  appendTo="body" styleClass="remove-padding">
                          <ng-template pTemplate>
                              <div class="comment-overlay">
                                  <ul >
                                      <li (click)="onUpdate(c);btnaction.hide()"><i class="fa fa-pencil"></i> Edit</li>
                                      <li (click)="deleteComment(c);btnaction.hide()" style="color:red"><i class="fa fa-trash"></i> Delete</li>
                                  </ul>
                              </div>
                            
                          </ng-template>
                      </p-overlayPanel>
                    <!-- {{setCommentDate(c.created_date)}} -->
                </div>
            }
         
      </div>
  </ng-container>

</div>


<div>
    
    <textarea 
        #commentText
        pInputTextarea
        class="inputfield w-full no-resize"
        [(ngModel)]="comment"
        placeholder="Add comment here... &#10;(Press enter to submit comment)"
        (keydown)="onEnter($event)"
    ></textarea>

    <button
    (click)="addComment()"
    [disabled]="!comment"
    pTooltip="{{active_comment_id > 0 ?'Update':'Add'}} Comment"
    tooltipPosition="top" 
    style="margin: 11px 6px;" pButton type="button" icon="pi pi-send" class="p-button-raised p-button-rounded"></button>
<!-- 
    <button (click)="onEnter($event)" class="btn btn-sm btn-success pull-right" [disabled]="!comment">
        <i class="fa fa-comment"></i>
        Add Comment
    </button> -->
</div>