
<form [formGroup]="referenceForm" (ngSubmit)="onSubmit()">
<p-dialog header="Header" [(visible)]="visible" [style]="{ width: '30vw' }">
    
    <ng-template pTemplate="header">
        <span class="text-xl ">{{action}} Reference Value</span>
    </ng-template>

    
        <div class="flex flex-column gap-2">
            <label htmlFor="refvalue">Enter Value:</label>
            <input pInputText id="refvalue" aria-describedby="refvalue-help" [required]="true"
                formControlName="value" />
        </div>

        <div class="flex flex-column gap-2 mt-2">
            <label htmlFor="isActive">Is Active</label>
            <p-inputSwitch formControlName="active" [required]="true"></p-inputSwitch>
        </div>

        <div class="flex flex-column gap-2 mt-2" *ngFor="let field of added_columns">
            <label htmlFor="{{field.field}}">{{field.label}}</label>
            <div [ngSwitch]="field.type" >
                <input pInputText formControlName="{{field.field}}" id="ref{{field.field}}" *ngSwitchCase="'text'" [required]="true" class="w-100"/>

                <p-inputNumber formControlName="{{field.field}}" id="ref{{field.field}}" *ngSwitchCase="'number'" [required]="true"  class="w-100"></p-inputNumber>
                <p-colorPicker formControlName="{{field.field}}" *ngSwitchCase="'color'" appendTo="body" [required]="true"></p-colorPicker>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" (click)="visible = false" label="Close" styleClass="p-button-text"></p-button>
            <p-button icon="pi pi-save" [disabled]="!referenceForm.valid" type="submit" label="Save" styleClass="p-button-text"></p-button>
        </ng-template>
  
</p-dialog>
<p-dialog header="Header" [(visible)]="bulk.visible" [style]="{ width: '30vw', height:'700px' }">
    
    <ng-template pTemplate="header">
        <span class="text-xl ">{{action}} Reference Value</span>
    </ng-template>

    
        <div class="flex flex-column gap-2">
            <label htmlFor="refvalue">Enter Value:</label>
            <textarea aria-describedby="refvalue-help" [required]="true"
                formControlName="value" ></textarea>
        </div>

 
        <div class="flex flex-column gap-2 mt-2" *ngFor="let field of added_columns">
            <label htmlFor="{{field.field}}">{{field.label}}</label>
            <div [ngSwitch]="field.type" >
                <input pInputText formControlName="{{field.field}}" id="ref{{field.field}}" *ngSwitchCase="'text'" [required]="true" class="w-100"/>

                <p-inputNumber formControlName="{{field.field}}" id="ref{{field.field}}" *ngSwitchCase="'number'" [required]="true"  class="w-100"></p-inputNumber>
                <p-colorPicker formControlName="{{field.field}}" *ngSwitchCase="'color'" appendTo="body" [required]="true"></p-colorPicker>
            </div>
        </div>

        <ng-template pTemplate="footer">
            <p-button icon="pi pi-times" (click)="visible = false" label="Close" styleClass="p-button-text"></p-button>
            <!-- <p-button icon="pi pi-save" [disabled]="!referenceForm.valid" (click)="submitBulk('add')" pTooltip="Adds new reference from the form" tooltipPosition="top" label="Add All" styleClass="p-button-text"></p-button> -->
            <p-button icon="pi pi-save" [disabled]="!referenceForm.valid" (click)="submitBulk('replace')"    label="Replace All" styleClass="p-button-text"></p-button>
        </ng-template>
  
</p-dialog>
</form>


<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>


<div class="p-d-flex mb-1">
    <div class="grid">
        <div class="col-6">
            <p-dropdown [filter]="true" filterBy="name" [showClear]="true" [options]="reference_fields"
                inputId="reference_seletion" optionLabel="displayName" [virtualScroll]="true" [itemSize]="30"
                styleClass="w-50 mb-2 no-bg" placeholder="Select Reference" [filter]="true" (onChange)="getReference()"
                filterBy="displayName" appendTo="body" autoWidth="false" [(ngModel)]="active_reference">


                <ng-template pTemplate="selectedItem">
                    <b><i class="pi pi-book" style="font-size:12px; margin-right: 3px;"></i> Reference :</b>
                    {{active_reference.displayName}}
                </ng-template>

                <ng-template let-item pTemplate="placeholderItem">
                    test
                </ng-template>
                <ng-template let-reference pTemplate="item">

                    <div>
                        {{reference.displayName}}


                    </div>
                </ng-template>


            </p-dropdown>
        </div>

        <div class="col-6">
            <div class="flex justify-content-end flex-wrap card-container">


                <p-button icon="pi pi-save" 
                label="Save Changes" 
                (click)="saveChanges()"
                *ngIf="active_reference != '' &&  active_reference != null && changes_identify"
                styleClass="p-button-raised p-button-secondary p-button-sm mr-2">
                </p-button>

                <p-button (click)="view2()" pTooltip="Bulk Update" tooltipPosition="bottom" icon="fa fa-pencil-square-o"
                    styleClass="p-button-raised p-button-success mr-2" label="Bulk Update"
                    *ngIf="active_reference != '' &&  active_reference != null"
                    class="flex align-items-center justify-content-center"></p-button>

                <p-button (click)="view()" pTooltip="Add New" tooltipPosition="bottom" icon="pi pi-plus"
                    styleClass="p-button-raised p-button-success mr-2" label="Add New"
                    *ngIf="active_reference != '' &&  active_reference != null"
                    class="flex align-items-center justify-content-center"></p-button>

                <p-button (click)="getReference()" pTooltip="Refresh" tooltipPosition="bottom" icon="pi pi-refresh"
                    styleClass="p-button-raised p-button-text p-button-secondary" *ngIf="active_reference != '' &&  active_reference != null"
                    class="flex align-items-center justify-content-center"></p-button>

            </div>
            <!-- <p-button type="button" icon="pi pi-refresh" pTooltip="Refresh" tooltipPosition="bottom" styleClass="p-button-raised p-button-primary pull-right" ></p-button> -->


        </div>
    </div>
</div>
<div style=" width: 100%">
    <div class="row m-1 border" style="border-radius: 7px;padding:2px 5px;background-color:#f6f6f6;"
        *ngIf="active_reference != '' && active_reference != null">
        <!-- <span style="float:left;width:fit-content;"><i class="pi pi-book"></i></span>     -->
        <div style="float:left;width:fit-content;" *ngFor="let b of bc;let i = index">
            <button class="pull-left" (click)="navigateBc(b,i)" 
            style="width: fit-content;border:none !important;background: none;font-size:15px;font-weight: 400;
                padding-top: 10px;padding-bottom:10px;
                cursor:{{i == (bc.length - 1)?'default':'pointer'}};
                color:{{i == (bc.length - 1)?'#495057':'#1e64e7'}};
                font-weight:{{i == (bc.length - 1)?'400':'600'}};
                text-decoration:{{i == (bc.length - 1)?'none':'underline'}};
                "> {{b.label}}
            </button> <i *ngIf="i < (bc.length - 1)" class="pi pi-chevron-right ml-2" style="margin-top:12px"></i>
        </div>


    </div>




    <p-table [value]="items" responsiveLayout="scroll" [rows]="10" [rowHover]="true"
        [styleClass]="'w-full  p-datatable-sm i95-table i95-table-sm'" [loading]="loading" >
        <ng-template pTemplate="header">
            <tr>

                <th style="padding-left:15px !important;">Value</th>
                <!-- <th>Type</th> -->
               
                <!-- <th>ALL</th> -->
                <th  *ngFor="let field of added_columns">{{field.label}}</th>
                <th>Active</th>
                <th style="width:120px;" class="text-center">Order</th>
                <th style="width:120px;"></th>
            </tr>

        </ng-template>

        <ng-template pTemplate="body" let-item  let-i="rowIndex">
            <tr>
                <td style="padding-left:15px !important;">
                    
                    <span *ngIf="with_sub" (click)="showChild(item)" style="font-weight:600;cursor:pointer;color:#1e64e7;text-decoration: underline;" pTooltip="Click here to view sub items."  tooltipPosition="right">{{ item.value }}</span>
                    <span *ngIf="!with_sub">{{ item.value }}</span>
                </td>
                <!-- <td>{{ item.type }}</td> -->
               
                <td  *ngFor="let field of added_columns">
                    <span *ngIf="field.type != 'color'">{{item.additional_data[field.field]}}</span>
                    <div [style]="{'height':'30px','width':'30px','background-color':item.additional_data[field.field]}" *ngIf="field.type == 'color'"></div>
                </td>
                <td><p-inputSwitch [(ngModel)]="item.active" (click)="deactivate(item.id,item.active)"></p-inputSwitch>
                    <!-- <td><p-inputSwitch [(ngModel)]="item.project_id" (click)="deactivate(item.id,item.active)"></p-inputSwitch> -->
                    </td>
                <td style="width:120px;" class="text-center">

                    <p-button (click)="changeOrder(i,'up')" icon="pi pi-caret-up" [disabled]="i==0"
                        styleClass="p-button-raised p-button-light p-button-sm mr-1 p-button-rounded"></p-button>

                    <p-button (click)="changeOrder(i,'down')" icon="pi pi-caret-down" [disabled]="i==(items.length-1)"
                        styleClass="p-button-raised p-button-light p-button-sm p-button-rounded"></p-button>

                </td>
               
                <td style="width:120px;" >

                    <p-button (click)="view(item.id)" icon="pi pi-pencil"
                        styleClass="p-button-raised p-button-info p-button-sm mr-1 p-button-rounded"></p-button>

                    <p-button (click)="delete(item.id)" icon="pi pi-trash"
                        styleClass="p-button-raised p-button-danger p-button-sm p-button-rounded"></p-button>

                </td>
            </tr>
        </ng-template>
    </p-table>

</div>