import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DndDropEvent, DndModule } from 'ngx-drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { TieredMenuModule } from 'primeng/tieredmenu';


@Component({
    selector: 'i95-kanban',
    templateUrl: './generalkanban.component.html',
    styleUrls: ['./generalkanban.component.scss'],
    standalone: true,
    imports: [TieredMenuModule, NgFor, NgIf, ButtonModule, DndModule, NgClass, TooltipModule]
})
export class GeneralkanbanComponent implements OnInit {


  
  @Input() items: any = [];
  @Input() loading: any= false;
  @Input() item_field: string = '';
  @Input() fields: any = [];
  @Input() tierdmenulist: any = [];
  @Input() tierdmenuitemlist: any = [];
  @Input() height_cut: any = 275;


  
  @Output() inLineChange = new EventEmitter<any>();
  @Output() openItem = new EventEmitter<any>();
  @Output() selectedItem = new EventEmitter<any>();


  drag_item: any = {}

  @ViewChild('tieredmenu', { static: false })
  tieredmenu!: ElementRef;

  @ViewChild('tieredmenuitem', { static: false })
  tieredmenuitem!: ElementRef;



  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  constructor(
    public commonservice: CommonService
  ) { }

  ngOnInit(): void {

    (async()=>{
      await this.commonservice.loadCommonAwait('users')
    })()
    

  }

  listItems(field:any){
    let list:any = [];
    if(this.items.length>0){
      for(let data of this.items){
        if(data[this.item_field]==field)
          list.push(data);
      }
    }

    return list;
  }


  isDue(isdue:boolean,dt:any):boolean{
    if(isdue && (moment().format('Y-MM-DD') > moment(dt).format('Y-MM-DD')))
      return true

    return false
  }
  
  listCount(field:any){
    let cnt:number = 0;
    if(this.items.length>0){
      for(let data of this.items){
        if(data[this.item_field]==field)
        cnt++
      }
    }
    return cnt;
  }

  openTieredMenu(e:any, grp:any){
    let tmenu:any = this.tieredmenu;
    this.selectedItem.emit(grp)
    tmenu.toggle(e);
  }

  openTieredMenuItem(e:any, d:any, grp:any){
    let tmenuitem:any = this.tieredmenuitem;
    let data:any =  { data: d, group: grp}
    this.selectedItem.emit(data)
    tmenuitem.toggle(e);
  }

  onEditComplete(e:any, new_value:any){



      var send_data:any = {
        field: e.field,
        rowdata: e.data,
        new_value: new_value
      };

    
      
      this.inLineChange.emit(send_data);


  }

  viewItem(data:any){
    this.openItem.emit(data);
    
  }

  onDragStart(event: DragEvent, data:any) {
    this.drag_item = data;
    console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {

    console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {

    console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {

    console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDraggableMoved(event: DragEvent) {

    console.log("draggable moved", JSON.stringify(event, null, 2));
  }

  onDragCanceled(event: DragEvent) {

    console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {

    console.log("dragover", JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, where:any) {
    if(where!=this.drag_item[this.item_field]){
      // alert(where);      
      let new_data:any = {};
      new_data.data = this.drag_item;
      new_data.field = this.item_field;
      this.onEditComplete(new_data, where);
    }

    // console.log("dropped", JSON.stringify(event, null, 2));
  }



  

}
