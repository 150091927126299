import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { GlobalService } from 'src/app/services/global.service';
import { SocketService } from 'src/app/services/socket.service';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import * as moment from 'moment';




@Component({
    selector: 'i95-module-comments',
    templateUrl: './module-comments.component.html',
    styleUrls: ['./module-comments.component.scss'],
    standalone: true,
    imports: [ConfirmDialogModule, SharedModule, ButtonModule, NgIf, NgFor, NgClass, FormsModule, InputTextareaModule, TooltipModule,OverlayPanelModule]
})
export class ModuleCommentsComponent implements OnInit  {
  @Input() parent_id: any = 0;
  @Input() module_code: any = 'general';
  @Input() type: any = 'form';
  @Input() comment_type: any = 'comment';
  @Input() height: any = 0;
  @Input() trigger: any = 0;
  @Input() emailTo: any = [];

  @Input() triggerEvent = new EventEmitter<any>();
  trigger_change_obj:any;

  loader: boolean = false;
  done: boolean = false;
  comment: any = '';
  comments_data: any = [];
  comment_date:any='';
  style:any='';
  url:any =  this.configservice.appConfig.apiurl+'comments/';

  @ViewChild('commentcontainer', { static: true })
  commentcontainer!: ElementRef;

  @ViewChild('commentText', { static: true })
  commentText!: ElementRef;

  @Output() comment_count = new EventEmitter<any>();


  constructor(
    public configservice: ConfigService,
    private http: HttpClient,
    public commonservice: CommonService,
    private confirmationService: ConfirmationService,
    private globalservice: GlobalService,
    private socketservice: SocketService
  ) { }

  ngOnDestroy() {
    this.trigger_change_obj.unsubscribe();
  }

  ngOnInit(): void {
    this.comment = '';


    this.trigger_change_obj = this.triggerEvent.subscribe((data)=>{
      if(data=='focus'){
          this.commentText.nativeElement.focus();
      }
    });

    if(this.parent_id){
      this.callComments();
      this.openSocket();
    }

    if(this.height){
      this.height -=160;
    }
    if(this.height){
      this.style = `height: ${this.height}px;`;
    }

    this.commonservice.loadCommon('users');

  }

  

  setCommentDate(d:any=''){
    this.comment_date = d;
  }

  recount(){
    var cnt:any = this.comments_data.length;
    this.comment_count.emit(cnt);
  }
  onScroll(e:any){
    // console.log(e);
    if(!this.done){
      let top = (e.srcElement.scrollTop);
      if(top<=0){
       this.loader = true;
       this.callComments(this.comments_data.length);
      }
    }

    
  }
  

  scrollToBottom(half:boolean=false) {
    setTimeout(() => {
      let scll: number = this.commentcontainer.nativeElement.scrollHeight;
      if(half){
        scll = (this.commentcontainer.nativeElement.scrollHeight/2);
      }
      this.commentcontainer.nativeElement.scrollTop = scll;

    },200); 
  }
  onEnter(e:any){
    if(this.comment==''){
    }else if(e.key=='Enter' && !e.shiftKey && this.comment!=''){
      this.addComment();
    }
  }

  
openSocket(){
  this.socketservice.socket.on('comment_'+this.module_code+'_'+this.parent_id, (return_data: any) => { 
    
    // console.log(return_data);
    if(Number(return_data.created_by)!=Number(this.configservice.userid)){     
      // console.log("open socket return data",return_data);
      if(return_data.is_deleted){             
        let c_index = this.globalservice.getDataIndex(this.comments_data,'id',return_data.id);
        if(c_index!=undefined){
          // this.comments_data.splice(c_index, 1);
          this.comments_data[c_index].is_deleted = 1;
        }
      }else if(return_data.updated){
        let _index = this.globalservice.getDataIndex(this.comments_data,'id',Number(return_data.id));
        if(_index!=undefined){
          this.comments_data[_index].comments = return_data.comments;
          this.comments_data[_index].modified_date = return_data.modified_date;
          this.comments_data[_index].is_deleted = return_data.is_deleted;

        }
      }else{ 
        this.comments_data.push(return_data); 
        this.scrollToBottom();
      }
     
        this.recount();
    }
  }); 

}

  callComments(start:number=0){

    this.loader = true;
    if(start<=0){
      this.comments_data = [];
    }
      let get_data: any = {
      parent_id: this.parent_id,
      module_code: this.module_code,
      comment_type:this.comment_type,
      start: start
    };
    if(this.parent_id){
      this.http.post<any>(this.url+'list', get_data).subscribe((r) => {
        if(r.data.length < 15){
          this.done = true;
        }
        if(r.data){
          for(let d of r.data){
            this.comments_data.unshift(d);
          }

        }
          // console.log(this.comments_data);
            
          setTimeout(() => {
            this.loader = false;
            if(start<=0){
              this.scrollToBottom();
            }else{
              this.scrollToBottom(true);
            }
            this.recount();
          },100); 
      });      
    }
    
  }

  addComment(){
    // this.comments_data.push(this.comment);
    // console.log(this.comments_data);
    let save_data: any = {
      parent_id: this.parent_id,
      module_code: this.module_code,
      comments: this.comment,
      notify_to: this.emailTo,
      comment_type:this.comment_type
    };
    
    this.loader = true;
    if(this.active_comment_id > 0){
      this.http.put<any>(this.url+'/'+this.active_comment_id,{comments:this.comment, parent_id: this.parent_id,module_code: this.module_code}).subscribe((r) => {
        setTimeout(() => {
          let _index = this.globalservice.getDataIndex(this.comments_data,'id',this.active_comment_id);
          this.active_comment_id = 0;
          this.loader = false;
          this.comments_data[_index].comments = this.comment;
          this.comments_data[_index].modified_date = moment('Y-MM-DD hh:ss:mm');
          this.comment = '';
        },500); 
      });
    }else{
      this.http.post<any>(this.url, save_data).subscribe((r) => {
        // console.log(r);
        this.comments_data.push(r.data);
        this.comment = '';
        setTimeout(() => {
          this.loader = false;
          this.scrollToBottom();        
          this.recount();
        },500); 
      });
    }
   

  
  }

  deleteComment(f:any={}){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this comment?',
      header: 'Delete File',
      icon: 'pi pi-trash',
      key: 'confirmdeletecomment',
      accept: () => {

        this.http.delete(this.url+f.id).subscribe(e=>{
          var r: any = e;
          if(r.success){ 
            let file_index = this.globalservice.getDataIndex(this.comments_data,'id',f.id);
            this.comments_data[file_index].is_deleted = 1;
            // this.comments_data.splice(file_index, 1);
            
            this.globalservice.showSuccess('Your comment successfully deleted.');
            this.recount();
          }
        });

      }
  });

    
  }

  active_comment_id:number = 0;

  onUndo(data:any){
    data.is_deleted =0;
    this.http.put<any>(this.url+'/'+data.id,data).subscribe((r) => {
      setTimeout(() => {
        let _index = this.globalservice.getDataIndex(this.comments_data,'id',data.id);
        this.comments_data[_index].is_deleted = 0;
      },500); 
    });
  }

  onUpdate(data:any){
    this.comment = data.comments;
    this.active_comment_id = data.id;
    this.commentText.nativeElement.focus();
  }
}
