import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { GlobalService } from '../global.service';
import { ConfigService } from '../config.service';




@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  url:any =  this.configservice.appConfig.apiurl+'company/';
  selected_company: any = []
  company_data: any = {}
  company_data_output: any = {}
  fields: any = [

    {
      "Id": "id",
      "Label": "",
      "Col": "col-sm-12 pt-3",
      "Type": "hidden",
      "Value": ""
    },
    {
      "Id": "company",
      "Label": "Company Name",
      "Col": "col-sm-12 pt-3",
      "Type": "input",
      "Value": "",
      "Required": true
    },

    {
      "Id": "email",
      "Label": "Email",
      "Col": "col-sm-12",
      "Type": "input",
      "Value": "",
      "Required": false
    },

    {
      "Id": "main_contact",
      "Label": "Main Contact",
      "Col": "col-sm-12",
      "Type": "user",
      "Value": "",
      "Required": true
    },
  
    {
      "Id": "address",
      "Label": "Address",
      "Col": "col-sm-12",
      "Type": "textarea",
      "Value": "",
      "Required": true
    }

  ]
  constructor(
    private http: HttpClient,
    public commonservice: CommonService,
    private globalservice: GlobalService,
    private configservice: ConfigService,

  ) { }
 
  post(){
    return this.http.post<any>(this.url, this.company_data_output);
  }
  get(id:any = ''){
    return this.http.get<any>(this.url+id);
  }
  put(id:any = ''){
    return this.http.put<any>(this.url+id, this.company_data_output);
  }
  delete(id:any = ''){
    return this.http.delete<any>(this.url+id);
  }
  deleteBulk(){
    var data: any = {ids: this.selected_company};
    return this.http.post<any>(this.url+'bulkDelete',data);
  }

}
