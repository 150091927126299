<div style="
float: left;
width: 100%;
height: calc(100% - 80px);">


    <form class="form-group" [hidden]="true">
        <label for="file">Choose File</label>
        <input type="file"
                id="fileuploaderbutton"
               #fileInputUpload
               multiple
               [value]="file_values"
               (change)="selectFiles($event)">
    </form>

    <div class="uploading-files-msg" *ngIf="start_uploading" style="font-size: 12px;">
        <i class="pi pi-spin pi-spinner"></i> {{for_uploading.length}} Items(s) Remaining...
    </div>
    <div class="upload-box pointer"  (click)="chooseFile(fileInputUpload)" [ngClass]="drop_container" [hidden]="start_uploading || disable_change">
        
        <div class="drop_upload ">
            <i class="pi pi-upload ic-upload-drop"></i>
			Drop your files here<br>
			or Browse for file
		</div>

    </div>

    <div class="file-container">

        <div class="file-item" *ngFor="let f of for_uploading"  [ngClass]="{'queuing': f.percent<=0}">

            <div class="file-item-ic">
                <img [src]="iconType(f)" alt="">
            </div>

            <div class="file-details">
                <div class="file-detail-line">{{f.name}}</div>
                <progress id="file" [value]="f.percent" max="100" class="file-detail-line"></progress>
            </div>

            <div class="file-icon-details">
                <!-- <i class="pi pi-spin pi-spinner"></i> -->
                <ng-container *ngIf="f.percent>0 && f.percent<100">
                    {{f.percent}}%<br>Uploading...
                </ng-container>
                <ng-container *ngIf="f.percent>=100 && !f.uploaded">
                    <i class="pi pi-spin pi-spinner"></i>
                    <div>Checking...</div>
                </ng-container>
                <ng-container *ngIf="f.uploaded">
                    <i class="pi pi-check"></i>
                    <div>Uploaded</div>
                </ng-container>
            </div>
            
            

        </div>



        <div class="file-item pointer" *ngFor="let f of files"  >

            <div class="file-item-ic"  (click)="openFile(f)">
                <img [src]="iconType(f)" alt="">
            </div>
            <div class="file-details uploaded_details" (click)="openFile(f)">
                <div class="file-detail-line">{{f.original_name}}</div>
                <div class="file-detail-line other_details">Uploaded by: {{commonservice.userName(f.created_by)}}</div>
                <div class="file-detail-line other_details">Uploaded date: {{f.created_date | date:'MMM d, y'}}</div>
            </div>
         <div class="file-icon-details">
            <i class="pi pi-download"  (click)="downloadFile(f)" ></i>
            <i [hidden]="disable_change" class="pi pi-trash"  (click)="deleteFile(f)"></i>
        </div>
            

        </div>



    </div>




</div>

<p-confirmDialog [style]="{width: '300px'}" key="confirmdeletefile" [baseZIndex]="10000" id="confirmdeletefile" #deletefile>
    <p-footer>
        <button pButton type="button" (click)="deletefile.accept()" label="Yes" icon="pi pi-check" class=" p-button-text pull-right"></button>
        <button pButton type="button" (click)="deletefile.reject()" label="No" icon="pi pi-times" class=" p-button-text pull-right"></button>
    </p-footer>
</p-confirmDialog>



<p-dialog 
    [(visible)]="show_viewer" 
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}" 
    [style]="{width: 'auto',maxWidth: '90%'}" 
    [modal]=true 
    [showHeader]=false 
    [dismissableMask]=true 
    appendTo="body"
    styleClass="file_viewer"
>
    
    <div class="img_loader" *ngIf="img_loading">
        <i class="pi pi-spin pi-spinner"></i>  Loading...
    </div>

    <img 
        class="img-viewer" 
        [src]="show_file_url" 
        [ngClass]="{'img_loaded': !img_loading}"
        alt="" *ngIf="show_file_type=='img'"
        (load)="onImageLoad()"
    >

    <ngx-doc-viewer
        [url]="show_file_url"
        viewer="google"
        style="width:150vh;height:90vh;"
        *ngIf="show_file_type=='pdf'"
></ngx-doc-viewer>
    <ngx-doc-viewer
        [url]="show_file_url"
        viewer="office"
        style="width:150vh;height:90vh;"
        *ngIf="show_file_type=='doc'"
></ngx-doc-viewer>

</p-dialog>