import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../common.service';
import { GlobalService } from '../global.service';
import { ConfigService } from '../config.service';





@Injectable()
export class ShipService {
    url:any =  this.configservice.appConfig.apiurl+'ship/';
    assigned_ship: any = [];
    ship_data: any = [];
    ship_data_output: any = [];
    selected_ship: any = [];

    ship_brand: any = [
      {value:'Azamara'},
      {value:'Celebrity Cruises'},
      {value:'Hapag Lloyd Cruises'},
      {value:'Pullmantur Cruises'},
      {value: 'Royal Caribbean International'},
      {value: 'SilverSea'},
      {value: 'Skysea Cruise Lines'},
      {value: 'TUI Cruises'}
    ];

    ship_class: any = [
      {value:'Century'},
      {value:'Cruise'},
      {value:'Edge'},
      {value:'Empress'},
      {value:'Europa'},
      {value:'Europa 2'},
      {value: 'Expedition'},
      {value:'Freedom'},
      {value:'Hanseatic Inspiration'},
      {value:'Hanseatic Nature'},
      {value:'Hanseatic Spirit'},
      {value:'Horizon'},
      {value:'Icon'},
      {value:'Intuition'},
      {value:'Mein Schiff'},
      {value:'Millennium'},
      {value:'Oasis'},
      {value:'Quantum'},
      {value:'R Class'},
      {value:'Radiance'},
      {value:'Solstice'},
      {value:'Sovereign'},
      {value:'Vision'},
      {value:'Voyager'},
      {value:'Xpedition'}
    ];

    fields: any = [

      {
        "Id": "id",
        "Label": "",
        "Col": "col-sm-12 pt-3",
        "Type": "hidden",
        "Value": ""
      },
      {
        "Id": "code",
        "Label": "Ship Code",
        "Col": "col-sm-12 pt-3",
        "Type": "input",
        "Value": "",
        "Required": true
      },
      {
        "Id": "code_number",
        "Label": "Vessel Code",
        "Col": "col-sm-12 pt-3",
        "Type": "input",
        "Value": "",
        "Required": true
      },
  
      {
        "Id": "name",
        "Label": "Ship Name",
        "Col": "col-sm-12 pt-3",
        "Type": "input",
        "Value": "",
        "Required": true
      },
  
      {
        "Id": "brand",
        "Label": "Ship Brand",
        "Col": "col-sm-12 pt-3",
        "Type": "normal-select",
        "Value": "",
        "Options": this.ship_brand,
        "Required": true
      },
  
      {
        "Id": "class",
        "Label": "Ship Class",
        "Col": "col-sm-12 pt-3",
        "Type": "normal-select",
        "Value": "",
        "Options": this.ship_class,
        "Required": true
      },
  
  
    ]



  constructor(
    
    private http: HttpClient,
    public commonservice: CommonService,
    private globalservice: GlobalService,
    private configservice: ConfigService,

  ) { 
   
  }

  

  post(){
    return this.http.post<any>(this.url, this.ship_data_output);
  }
  get(id:any = ''){
    return this.http.get<any>(this.url+id);
  }
  put(id:any = ''){
    return this.http.put<any>(this.url+id, this.ship_data_output);
  }
  delete(id:any = ''){
    return this.http.delete<any>(this.url+id);
  }
  deleteBulk(){
    var data: any = {ids: this.selected_ship};
    return this.http.post<any>(this.url+'bulkDelete',data);
  }



  
}
