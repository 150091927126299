import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, Output, ViewContainerRef, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ContextMenuModule } from 'primeng/contextmenu';
import { CommonService } from 'src/app/services/common.service';
import { ConfigService } from 'src/app/services/config.service';

import * as moment from 'moment-timezone';
import { GlobalService } from 'src/app/services/global.service';
import { CheckboxModule } from 'primeng/checkbox';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { UserDropdownWizardComponent } from '../../user-dropdown-wizard/user-dropdown-wizard.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgClass, NgTemplateOutlet } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';



@Component({
    selector: 'i95-group-tables',
    templateUrl: './grouptable.component.html',
    styleUrls: ['../customtable/customtable.component.scss'],
    standalone: true,
    imports: [ContextMenuModule, DialogModule, OverlayPanelModule, SharedModule, NgFor, FormsModule, ButtonModule, TooltipModule, NgIf, NgSwitch, NgSwitchCase, InputSwitchModule, NgSwitchDefault, UserDropdownWizardComponent, DropdownModule, CalendarModule, InputNumberModule, InputTextareaModule, InputTextModule, NgClass, TableModule, RippleModule, CheckboxModule, NgTemplateOutlet]
})
export class CustomGrouptableComponent implements OnInit {
 
  @Input() appScope: any;
  @Input() groupBy: any = false;
  @Input() selection: boolean = false;
  @Input() showAction: boolean = true;
  @Input() filterValue: any = {};
  @Input() allowEdit: boolean = false;
  @Input() allowFilter: boolean = false;
  @Input() get show_table(): any { return this.show; }
  @Input() type: any;
  @Input() styleClass: any='';
  @Input() seemore_fields: any;
  @Input() fields: any;
  @Input() value: any = [];
  @Input() reference:any = {};
  @Input() loading: any= false;
  @Input() allow_filter: any= false;
  @Input() table_height: any = 300;
  @Input() totalRecords: any = 300;
  @Input() no_data_msg: any = 'No records found';

  
  @Input() general_filter: any = [];
  @Input() general_search: any = [];


  @Output() onLazyLoad = new EventEmitter<any>();
  @Output() openItemMenu = new EventEmitter<any>();
  @Output() inLineChange = new EventEmitter<any>();
  @Output() itemChecked = new EventEmitter<any>();

  @Output() filterChanged = new EventEmitter<any>();

  selected_item: any = [];
  selected_header: any = [];

  @ViewChild('i95table', { static: true })
  i95table!: ElementRef;

  @ViewChild('comments') comments!: ElementRef;

  event:any = {};
  
  isExpanded:boolean = false;
  expandedRows:any = {};
  temDataLength:number = 0;
  switch_model:any = {};
  general_model:any = '';
  template:any = '';
  componentClass:any = '';
  imageComponentClass:any = {};
  dropdowns: any = {};
  custom_filter: any = {};
  rows: any = 50;
  show: any = false;
  selected_context: any = {}
  target_context: any = {}
  open_comments: boolean = false
  parse_comments: any ={}
  context_item: any = [
    {
      label: 'Copy', 
      icon: 'pi pi-copy', 
      command: () => {
          console.log(this.selected_context);
      }
    },

    {
      label: 'Comments', 
      icon: 'pi pi-comments', 
      command: () => {
          if(this.selected_context.data.comment_data){
            let data = JSON.parse(this.selected_context.data.comment_data);
            this.parse_comments = data;
          }else{
            this.parse_comments = {};
          }

          setTimeout(() =>{
            this.op_comment.toggle(this.target_context);
          }, 100);
          setTimeout(() =>{
            this.comments.nativeElement.scrollTop = this.comments.nativeElement.scrollHeight
          }, 500);
    
      }
    },
  ];

  sortfield: any = 'id';
  sortorder: any = 1;
  defaultDate: any = new Date();

  constructor(
    private renderer:Renderer2,
    private container: ViewContainerRef,
    public commonservice: CommonService,
    public globalservice: GlobalService,
    public configservice: ConfigService,
    public changedetector: ChangeDetectorRef,
    ) {
   }

   set show_table(data:any){
     if(!data){
       this.no_data_msg = 'You\'re not allowed to view this project.';
       this.value = [];
       this.show = false;
     }else{
       this.show = true;
       this.no_data_msg = 'No records found.';
     }
   }
   
  ngOnInit(): void {
      // console.log(this.hello);
      // this.renderer.setProperty(this.hello.nativeElement,'innerHTML',"Hello {{te}} Angular")
      // console.log(this.renderer);
      // console.log(this.appScope);

      if(this.general_filter.length > 0){
        this.table_height -= 60;
      }
      
      this.template = '<div>hello {{name}}</div> <button (click)="test()">Test Event</button>';

      this.custom_filter = {};
      // this.container.clear();
      // this.container.createEmbeddedView(this.templateref, {item: {name: 'John'}});
      for(let f of this.fields) {
        if(f.Options) {
          this.dropdowns[f.Id] = f.Options;
        }
        // set the default sorting order
        if(f.defaultSort){
          this.sortfield = f.Id;
          if(f.defaultOrder){
            if(f.defaultOrder.toLowerCase()=='desc'){
              this.sortorder = -1;
            }else{
              this.sortorder = 1;
            }
          }
        }

      }

      this.imageComponentClass = {
        commonservice: this.commonservice
      };



  }



  op_comment:any
  openContext(op:any, evnt:any){
    console.log(op);
    console.log(evnt);
    this.target_context = evnt;
    this.op_comment = op;
  }

  comment_content: any = ""
  addComment(){
    let data:any = {};
    if(this.comment_content){
      data = { user: this.configservice.userid, datetime: moment().zone(0).format('Y-MM-DD: HH:mm:ss'), comment: this.comment_content };
      if(!this.parse_comments[this.selected_context.col.Id]){
        this.parse_comments[this.selected_context.col.Id] = [];
      }
      this.parse_comments[this.selected_context.col.Id].push(data);
      this.comment_content = '';

      // console.log(this.parse_comments);
      let new_data = JSON.stringify(this.parse_comments);
      var send_data:any = {
        field: 'comment_data',
        rowdata: this.selected_context.data,
        new_value: this.parse_comments,
        rowindex: this.selected_context.index
      };
      // console.log(send_data);
      this.inLineChange.emit(send_data);
      setTimeout(() =>{
        this.comments.nativeElement.scrollTop = this.comments.nativeElement.scrollHeight
      }, 500);

    }

  }



  rowClass(row:any={}){
    let clss = '';

    if(this.appScope.rowClass){
      let rowClass:any = this.appScope.rowClass.split(',');
      for(let r of rowClass){
        let p:any = r.split(':');
        let con:any = (p[1]) ? p[1] : 'false';
        if(eval(con)){
          let ngclass = (p[0]) ? ' '+p[0].replaceAll(`'`,``) : '';
          clss += ngclass;
        }
      }
    }
    clss += (this.selectedItm(row.entity)) ? ' item-selected' : '';
    clss += (row.entity.disable_select) ? ' disable-select' : '';
    return clss;
  }

  imgUrl(id:any=0){
    let img_url:any = this.configservice.appConfig.apiurl+'upload/file/thumbnail/'+id;
    return img_url;
  }
  imageData(data:any='', field_name:any=''){
    if(data!=null && data!=''){
      let new_data:any = JSON.parse(data);
      // console.log(new_data);
      let html:string = '';
      if(new_data.thumbnail){
        for(let i of new_data.thumbnail){
          html +='<img (click)="commonservice.openFile('+i+')" src="'+this.configservice.appConfig.apiurl+'upload/file/thumbnail/'+i+'"/>';
        }
      }
      return html;
    }else{
      return '';
    }

  }
  
  onEditInit(e:any){
    // console.log(e);
    let value:any = e.data[e.field.Id];
    if(e.field.Type=='date'){
      if(value=='0000-00-00' || !value){ 
        this.general_model = '';
      }else{
        this.general_model = new Date(value);
      }
    }else{
      this.general_model = e.data[e.field.Id];
    }
  }
  onEditComplete(e:any){
    let value:any = e.data[e.field.Id];
    let changed = false;
    if(e.field.Type=='date'){
      if(value=='0000-00-00' || !value){ 
        value = '';
      }else{
        value = new Date(value);
      }
      let format_model = moment(this.general_model).format('Y-MM-DD');
      let format_value = moment(value).format('Y-MM-DD');
      if(format_model!=format_value){
        changed = true;
      }
      this.general_model = moment(this.general_model).format('Y-MM-DD');
    }else{
      if(this.general_model!=value){
        changed = true;
      }
    }

    if(changed){
      var send_data:any = {
        field: e.field.Id,
        field_data: e.field,
        rowdata: e.data,
        new_value: this.general_model,
        rowindex: e.index
      };
      
      // console.log(send_data);
      this.inLineChange.emit(send_data);
    }
  }

  dateFormat(data:any, config: any){
    let strdate = '';
    if(data=='0000-00-00' || !data){

    }else if(config.Format){
      strdate = moment(data).format(config.Format);
    }else{
      strdate = moment(data).format('MM/DD/YYYY');
    }

    return strdate;
  }

  dateFormatTZ(data:any, config: any){
    let strdate = '';
    let tz = moment().zone();
    if(data=='0000-00-00' || !data){

    }else if(config){
      strdate = moment(data).zone(tz).format(config);
    }else{
      strdate = moment(data).zone(moment().zone()).format('MM/DD/YYYY');
    }

    return strdate;
  }


  headChecked(data:any) {    
    return true;
  }


  datachanged:boolean = false;
  selectedAllHead(data:any) {    
    if(this.selectedHeadItm(data)){
      let child_selected_item:any = this.globalservice.getMultiData(this.selected_item, this.groupBy, data[this.groupBy]);
      for(let c of child_selected_item){
        let child_index = this.selected_item.indexOf(c);
        this.selected_item.splice(child_index, 1);
      }
    }else{
      let child_item:any = this.globalservice.getMultiData(this.value, this.groupBy, data[this.groupBy]);
      for(let c of child_item){
        let child_index = this.selected_item.indexOf(c);
        if(child_index < 0) this.selected_item.push(c)
      }
    }
    console.log(this.selected_item)
    this.datachanged = true;
    this.onRowSelect({})
  }
  selectedHeadItm(data:any) {    
    let child_item:any = this.globalservice.getMultiData(this.value, this.groupBy, data[this.groupBy]);
    let child_selected_item:any = this.globalservice.getMultiData(this.selected_item, this.groupBy, data[this.groupBy]);
    return (child_selected_item.length==child_item.length)
  }
  selectItm(data:any) {    
    if(data.disable_select){
      return;
    }
    if(this.selectedItm(data)){
      let itm_indx = this.selected_item.indexOf(data);
      this.selected_item.splice(itm_indx, 1)
    }else{
      this.selected_item.push(data);
    }
    this.onRowSelect({})

  }
  selectedItm(data:any):boolean {    
    return (this.selected_item.includes(data));
  }

  header_checked:boolean = false;
  checkAll(e:any){
    // console.log(this.selected_item)

    let total_allowed = 0;
    for(let d of this.value){
      if(!d.disable_select){
        total_allowed++;
      }
    }

    if(this.selected_item.length!=total_allowed){
      // this.selected_item = this.value;
      this.selected_item = [];
      for(let d of this.value){
        if(!d.disable_select){
          this.selected_item.push(d);
        }

      }
    }else{
      this.selected_item = [];
    }
    this.onRowSelect({});
  }
  onRowSelect(e:any){
    let total_allowed = 0;
    for(let d of this.value){
      if(!d.disable_select){
        total_allowed++;
      }
    }
    console.log(this.selected_item);

    if(this.selected_item.length!=total_allowed){
      this.header_checked = false;
    }else{   
      this.header_checked = true;
    }

    this.itemChecked.emit(this.selected_item);
  }

  getList(e:any, tbl:any='') {
    // alert(this.show)
    this.event = e;
    
    this.onRowSelect({});
    this.expandedRows = {};
    this.selected_item = [];
    this.header_checked = false;
    // console.log(this.event);
    if(!this.refreshing_table){
      this.onLazyLoad.emit(e);
    }
    
    setTimeout(() => {
      this.refreshing_table = false;
    }, 200);
  }
  // prevent from multiple call when refreshing.
  refreshing_table: boolean = false;
  refreshTable(){
    
    this.refreshing_table = true;
    let tbl:any = this.i95table;
    tbl.clear();
    this.custom_filter = {};
    setTimeout(() => {
      this.refreshing_table = false;
      
      setTimeout(() => {
        this.event.sortField = this.sortfield;
        this.event.sortOrder = this.sortorder;
        this.getList(this.event);
      }, 100);
    }, 100);

  }

  filterChange(field:any='', value:any) {

    if(field.Type=='date') {
      this.custom_filter[field.Id]=value;
      if(value!=''){
        value = moment(value).format('Y-MM-DD');
      }
    }
    // console.log(this.custom_filter);

    this.event.filters[field.Id].value = value;
    this.getList(this.event);
  }

  openMenu(data:any = {},e:any = {}){
      this.openItemMenu.emit({data:data, event:e});
  }

  switchValue(data:any){
    return data;
  }
  switchChanged(col:any,data:any, rindex:number): boolean {
    if(!col.enableCellEdit || !this.allowEdit){
      return false;
    }

    let truevalue = col.trueValue.value;
    let falsevalue = col.falseValue.value;
    // data[col.Id]= 1;
    let new_value = truevalue;
    if(data[col.Id]==truevalue){
      new_value = falsevalue;
    }




      var send_data:any = {
        field: col.Id,
        field_data: col,
        rowdata: data,
        new_value: new_value,
        rowindex: rindex
      };
      this.inLineChange.emit(send_data);
      
      return true;

  }

  allowShowField(field:any=''){
    var ret = true;
    if(this.seemore_fields){
      ret = this.seemore_fields.includes(field);
    }
  
    return ret;
  
  }

  getOptions(col:any){
    if(col.reference){
      return (this.reference[col.reference]) ? this.reference[col.reference] : col.Options;
    }
    return [];
  }
  columnStyle(col:any={}){
    let style = "";
    if(col.width){
      style += "width: "+col.width+";";
    }else{
      style += "width: 120px";
    }
    return style;

  }

  chnage(){
    this.componentClass = {
      name: 'wow!',
      onEvent: () => { 
          this.componentClass.name = 'world';

       }
    };
  }

  page:number = 1;
  get pageValue(): number { return this.page; }
  set pageValue(data: number) {
    this.page = Number(data);
    this.changePage();
  }
  totalPage(arr:boolean = false) {
    let total_page: number = Math.ceil(this.totalRecords/this.event.rows);
    let ret:any; 
    if(arr){
      ret = [];
      for (let i = 1; i <= total_page; i++) {
        ret.push(i);
      }
    }else{
      ret = total_page;
    }
    
    return ret;
  }
  onChangePage(page:any=1){
    console.log(this.page);
    console.log(page);
  }
  changePage(action:any=""){
   
    if(action=='+'){
      this.page +=1;
      this.selected_item = [];
    }else if(action=='-'){
      this.page -=1;
      this.selected_item = [];
    }else{

    }
    let multiplier = (this.page-1);
    let frst = multiplier*this.event.rows;
    // console.log(frst);
    this.event.first = frst;

    setTimeout(() => {
      this.getList(this.event);
      
    },300);
  }

  
  filter:any = {
    main: this,
    searching:'',
    value: {},
    data: {search:'', filter:{}},
    search: '',
    clear(){
      this.value = {};
      this.search = '';
      // this.main.refreshing_table = false;
      this.data = {search:'', filter:{}};
      this.main.filterChanged.emit(this.data);
    },
    refresh(){
      this.main.filterChanged.emit(this.data);
    },
    inDate(field:any, value:any){
      let sdate = moment(`${value.year}-${value.month+1}-${value.day}`).format('YYYY-MM-DD');
      // console.log(sdate);
      let options = [];
      let filter_options: any = (field.inGeneralFilter.options) ? field.inGeneralFilter.options : [];
      for(let o of filter_options){
        options.push(moment(`${o}`).format('YYYY-MM-DD'))
      }
      return options.includes(sdate);
    },
    executeSearch(text:string=''){
      this.data.search = text;   
      this.main.filterChanged.emit(this.data);
    },
    changeSearch(event:any){
      
      clearTimeout(this.searching);
      
      this.searching = setTimeout(() => {
        this.executeSearch(event.target.value);
      }, 1000);
      // this.main.filterChanged.emit(this.data);
    },
    change(field:any='', value:any){
      this.value[field.Id] = value;
      let check_value = JSON.parse(JSON.stringify(this.value));
      if(field.Type=='date') {
        if(value!=''){
          value = moment(value).format('Y-MM-DD');
        }
        check_value[field.Id] = value;
      }


      this.data.filter = check_value;
      this.main.filterChanged.emit(this.data);
    }

  }

  checkComment(field:any='', data:any={}){
    console.log()
    if(!data){
      return false;
    }
    let commentjson = JSON.parse(data);
    // if(field=='switch_3'){
    //   console.log(field,data);

    // }
    
    if(!commentjson[field]){
      return false;
    }
    return true;
  }

  expandAll() {
    
    if(!this.isExpanded){
      this.loading = true;
      for(let v of this.value){
        if(!this.expandedRows[v[this.groupBy]]){
          this.expandedRows[v[this.groupBy]] = true;
        }
      }
      this.loading = false;
    } else {
      this.expandedRows={};
    }
    this.isExpanded = !this.isExpanded;
  }
  onRowExpand() {
    console.log("row expanded", Object.keys(this.expandedRows).length);
    if(Object.keys(this.expandedRows).length === this.temDataLength){
      this.isExpanded = true;
    }
  }
  onRowCollapse() {
    console.log("row collapsed",Object.keys(this.expandedRows).length);
    if(Object.keys(this.expandedRows).length === 0){
      this.isExpanded = false;
    }
  }



}
