import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';


@Injectable({
  providedIn: 'root'
})
export class ReferenceV2Service {

  module_id: any = 0;
  field: any = '';
  type: any = '';
  parent_id: any = 0;
  project_id: any = 0;
  assigned_reference: any = [];
  filter_data:any = {}
  all_reference: any = {}
  module_reference:any = [];

  readonly baseURL = this.config.getConfig().apiurl + 'reference';

  constructor(private http: HttpClient, private config: ConfigService) {

  }

  setData(data:any={}){
    if(data.module_id) this.filter_data.module_id = data.module_id
    if(data.field) this.filter_data.field = data.field
    if(data.parent_id) this.filter_data.parent_id = data.parent_id
    if(data.project_id) this.filter_data.project_id = data.project_id
    if(data.type) this.filter_data.type = data.type
  }




  getItems(){
    return this.http.get(this.baseURL+'items', this.filter_data);
  }





  setReference(params:any = []){

    return new Promise(resolve => {
    this.all_reference = {}
        this.http.get(this.baseURL+'?module_id='+params.module_id+'&project_id='+params.project_id).subscribe((res:any)=>{
            // console.log(res);
            // for(let r of res.data){
            //     if(!this.all_reference[r.field]) this.all_reference[r.field] = []
            //     this.all_reference[r.field].push(r);
            // }
            resolve(res.data)

        });
    });
  }



  async constructReference(params:any = []){

          let return_reference:any = {};
          let allreference:any = await this.setReference(params);
          if(allreference){
            for(let r of allreference){
              if(!return_reference[r.field])
                return_reference[r.field] = []

                if(r.additional_data!='\'\'')
                  r.additional_data = JSON.parse(r.additional_data);

                return_reference[r.field].push(r);
            }
          }
            return return_reference;
  }
  getReference(params:any = []){
    return this.http.get(this.baseURL+'?ref='+params.ref+'&module_id='+params.module_id+'&project_id='+params.project_id);
  }

  getDetails(id:number = 0){
    return this.http.get(this.baseURL+'/'+id);
  }

  save(data:any,id:number = 0){
    console.log('data',data);
    console.log('id',id);
    if(id != 0){
      return this.http.put(this.baseURL+'/'+id,data);
    }else{
      return this.http.post(this.baseURL,data);
    }
  }
  bulkSave(data:any){


      return this.http.post(this.baseURL+'/bulkSave',data);

  }

  save_order(ids:object[]){

      return this.http.put(this.baseURL+'/save_order',{data:ids});

  }

  delete(id:number = 0){
    return this.http.delete(this.baseURL+'/'+id);
  }

  deactivate(id:number = 0,data:any){
    return this.http.put(this.baseURL+'/'+id+'?action=deactivate',data);
  }

  getDropdownOption(ref:any,form:any={},parent:any='',module=''){
    let parent_id = 0;
    let child_value:any = [];

    if(module==='fuelit')
    {
      if(ref == 'priorities'){
        if(form.value.delivery_method == null || form.value.delivery_method.length <=0){
          return [];
        }
      }
    }
    

    if(parent != ''){
      let form_parent =  form.value[parent];
      let _parent =  this.module_reference.find((obj:any)=>(obj.value === form_parent));
        if(_parent != undefined){
          parent_id = _parent['id'];
        }else{
          return [];
        }
    }  

    this.module_reference.forEach((e:any) => {
      if(parent != ''){
        if(e.parent_id == parent_id && e.field === ref && e.active == 1){
          child_value.push(e);
        }
      }else{
        if(e.field === ref && e.parent_id == 0 && e.active == 1){
          child_value.push(e);
        }
      }
    });
    return child_value;
  }
}
