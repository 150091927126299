import { Component, OnInit, Input, EventEmitter, Output, ViewChild, HostListener, HostBinding, ElementRef  } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
    
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigService } from 'src/app/services/config.service';

import { ConfirmationService, SharedModule } from 'primeng/api';
import * as moment from 'moment';
import { GlobalService } from 'src/app/services/global.service';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';


@Component({
    selector: 'i95-upload-attachment',
    templateUrl: './upload-attachment.component.html',
    styleUrls: ['./upload-attachment.component.scss'],
    providers: [ConfirmationService],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, NgFor, ConfirmDialogModule, SharedModule, ButtonModule, DialogModule, NgxDocViewerModule, DatePipe]
})
export class UploadAttachmentComponent implements OnInit {
  
  @Input() item_id: any;
  @Input() parent_id: any = 0;
  @Input() module_code: any = 'general';
  @Input() type: any = 'attachment';
  @Input() disable_change: boolean = false;

  files:any[] = [];
  for_uploading:any = [];
  selected_files:any = [];
  file_values:any = [];
  drop_container: string = '';
  start_uploading: boolean = false;
  img_loading: boolean = false;
  show_viewer: boolean = false;
  total_uploaded: number = 0;
  total_uploading: number = 0;
  api: string = 'upload/';

  
  
  @Input() triggerEvent = new EventEmitter<any>();
  trigger_change_obj:any;
  
  @Output() file_count = new EventEmitter<any>();

  
  @ViewChild('fileInputUpload', { static: true })
  uploadbtn!: ElementRef;


  constructor(
    private userservice: UserService,
    public commonservice: CommonService,
    public configservice: ConfigService,
    private confirmationService: ConfirmationService,
    private globalservice: GlobalService,
    private http: HttpClient,
  ) { }



  // @HostBinding('style.background-color') private background = '#ffffff';

  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.drop_container = 'has_file';
  }

  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.drop_container = ''
  }

  // Drop Event
  @HostListener('drop', ['$event']) public drop(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.drop_container = '';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.startUpload(files);
    }
  }

  
  ngOnDestroy() {
    this.trigger_change_obj.unsubscribe();
  }
  
  ngOnInit(): void {


    
    this.trigger_change_obj = this.triggerEvent.subscribe((data)=>{
      if(data=='upload'){
          this.uploadbtn.nativeElement.click();
      }
    });

    setTimeout(() => {
      this.commonservice.loadCommon('users');
  
    },3000);

    if(this.parent_id>0){
      this.getUploadFiles(this.parent_id);
    }

  }

  recount(){
    var cnt:any = this.files.length;
    this.file_count.emit(cnt);
  }

  chooseFile(f:any={}){
    f.click();
  }

  iconType(f:any={}){
    let asset_path = 'assets/img/ic_file/';
    let default_ic = 'unknown';

    var type = '';
    if(f.id){
      var i = f.file_name.split('.');
      type = f.mime_type;
    }else{
      var i = f.name.split('.');
      type = f.type;
    }

    var ext = i[i.length-1].toLowerCase();
    let icon = '';
    if(f.thumbnail){

      icon = this.configservice.getConfig().apiurl+'upload/file/thumbnail/'+f.id;
    }else{
      if(type=='application/pdf'){
          default_ic = 'pdf';
      }
      if(type.match('image/*')){
          default_ic = 'img';
      }
      if(type.match('video/*')){
          default_ic = 'video';
      }
      if(ext=='zip' || ext=='rar' || ext=='7z' || ext=='bin'){
          default_ic = 'zip';
      }
      if(ext=='xlsx' || ext=='xls' || ext=='csv'){
          default_ic = 'xl';
      }
      if(ext=='docx' || ext=='doc'){
          default_ic = 'doc';
      }
      if(ext=='pptx' || ext=='ppt' || ext=='odp'){
          default_ic = 'ppt';
      }
      icon = asset_path+default_ic+'.svg';
    }
    



    return icon;
  }

  getUploadFiles(id:any=0){

    // console.log(this.http);
    this.http.get(this.configservice.getConfig().apiurl+`upload/list/${id}/${this.module_code}/${this.type}`).subscribe(e=>{
      // console.log(e);
      let all:any = e;
      this.files = all.data;
    });

  }

uploadFile(){
    let file = this.for_uploading[0];

    let formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('parent_id',this.parent_id);
      formData.append('module_code',this.module_code);
      formData.append('type',this.type);
      formData.append('group_id',this.group_id);
    
    this.http.post(this.configservice.getConfig().apiurl+'upload',formData,{
      reportProgress:true,
      observe:"events"
    })
      .subscribe((event) => {
        let e: any = event;
        if(e.type==1){
          file.percent = Math.round(e.loaded/e.total)*100;
        }

        if(e.body){    
 
          file.uploaded = 1;
          setTimeout(() => {
            // console.log(e);
            if(this.files==null){
              this.files = [];
            }
            this.files.unshift(e.body.data);
            this.for_uploading.splice(0, 1);
            if(this.for_uploading.length>0){
                setTimeout(() =>{
                  // console.log(e);
                  
                  this.uploadFile();
                },500);
              }else{
                this.start_uploading = false;
              }
              this.recount();
          },1000);
        }

      }, (err) => {
      //send error response
    });

  }

  onImageLoad(){
    // alert();
    this.img_loading = false;
  }

  clearUpload(){
    this.file_values = '';
    this.total_uploaded = 0;
    this.total_uploading = 0;
    this.for_uploading = [];
    this.start_uploading = false;
  }

  group_id:any ='';
  startUpload(files:any=[]):boolean{
    this.group_id='';
    this.group_id = this.parent_id+"-"+Number(moment().format('YYYYMMDDHms') + '' + Math.floor(1000 + Math.random() * 9000));

    if(this.disable_change)
      return false;

    this.clearUpload();
    for(let f of files){
      f.percent = 0;
      this.for_uploading.push(f);
    }
    this.start_uploading = true;
    console.log(this.for_uploading);
    
    this.total_uploading = this.for_uploading.length;
    this.total_uploading = this.for_uploading.length;

    this.uploadFile();
    return true;
  }
  selectFiles(event:any) {
    // this.progressInfos = [];
      console.log(this.file_values);
      setTimeout(() => {
        this.file_values = [];
      },100);

    let files = event.target.files;
    this.startUpload(files);

  }



  show_file_type:any = '';
  show_file_url:any = '';
  openFile(f:any={}){
    this.show_viewer = true;
    if(f.mime_type.match('image/*')){
      this.show_file_type = 'img';
      this.img_loading = true;
    }
    if(f.mime_type.match('application/pdf')){
      this.show_file_type = 'pdf';
    }


    var i = f.file_name.split('.');
    var ext = i[i.length-1].toLowerCase();
      if(ext=='xlsx' || ext=='xls' || ext=='csv'){
          this.show_file_type = 'doc';
      }
      if(ext=='docx' || ext=='doc'){
          this.show_file_type = 'doc';
      }
      if(ext=='pptx' || ext=='ppt' || ext=='odp'){
          this.show_file_type = 'doc';
      }

      this.show_file_url = '';
      this.http.get(this.configservice.getConfig().apiurl+'upload/geturl/'+f.id).subscribe(e=>{
        var r: any = e;
        if(r.success){ 
          this.show_file_url =r.data;
        }
      });



  }
  downloadFile(f:any={}){
    let url = this.configservice.getConfig().apiurl+'upload/file/view/'+f.id;
    window.open(url, "_blank");
  }
  deleteFile(f:any={}){
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this file?',
      header: 'Delete File',
      icon: 'pi pi-trash',
      key: 'confirmdeletefile',
      accept: () => {

        this.http.delete(this.configservice.getConfig().apiurl+'upload/'+f.id).subscribe(e=>{
          var r: any = e;
          if(r.success){ 
            let file_index = this.globalservice.getDataIndex(this.files,'id',f.id);
            this.files.splice(file_index, 1);
            this.globalservice.showSuccess('File successfully deleted.');
            
            this.recount();
          }
        });

      }
  });

    
  }



}
