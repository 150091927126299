import { Injectable } from '@angular/core';
import { FormGroup,  UntypedFormBuilder,  Validators  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { GlobalService } from './global.service';
import { CustomCookieService } from './custome-cookie.service';

import { Observable } from 'rxjs';

import * as moment from 'moment-timezone';


@Injectable()
export class UserService {
  url: string = this.config.getConfig().apiurl;
  constructor(
    private fb:UntypedFormBuilder, 
    private http: HttpClient,
    private config: ConfigService,
    public globalservice: GlobalService,
    public cookieService: CustomCookieService
    ) {
      // console.log('api_url', this.url);

     }

  user_details: any = {};
  formModel = this.fb.group({
    firstName :['', Validators.required],
    lastName :['',Validators.required],
    middleName :[''],
    Email :['',Validators.email],
    Passwords : this.fb.group({
      Password :['',[Validators.required, Validators.minLength(4)]],
      ConfirmPassword :['',Validators.required]
    }),

  });


  // loadUserprofile
  // setUserprofile
  // loadUsers
  // saveUser
  // getUserData
  // getUserShips
  // getUserProjects
  // getUserAccess
  // getUserDepartments
  // getAdditionalData
  // saveAdditionalData
  // getNotification
  // readAllNotification
  // getUserProfile
  // setUserAccess
  // login
  // checkAccess


  user_profile: any = {};

  // import { GlobalService } from './global.service';
  loadUserprofile(){
         return this.http.get<any>(this.url+'user/userprofile');
  }

  setUserprofile(){
        this.loadUserprofile().subscribe(e => {
          
          let data = e;
          if(data.success){
            var enc = this.config.encrypt(JSON.stringify(data.data));
            localStorage.setItem('user_profile',enc);  
            this.getUserProfile();  
          }
      
        },(error) => {        
          
        });
  }


  loadUsers(event:any = {}){
    return this.http.post(this.url+'admin/usermanagement/getusers', event);
  }

  saveUser(){
    var save_data:any = Object.assign({}, this.user_details);
    // console.log('save user',save_data);


    // delete save_data.expire_date;
    if(save_data.expire_date){
      save_data.expire_date = moment(save_data.expire_date).format('YYYY-MM-DD');
    }

    if(!save_data.expire_date || save_data.expire_date==''){
      save_data.expire_date = '0000-00-00';
    }
    return this.http.post(this.url+'user/saveuser', save_data);
  }
  deleteUser(data:any = {}){
    return this.http.delete(this.url+'user/'+data.id);
  }

  getUserData(id:any){
    return this.http.get(this.url+'user/getuser/'+id);
  }
  getUserShips(id:any){
    return this.http.get(this.url+'user/getship/'+id);
  }
  getUserProjects(id:any){
    return this.http.get(this.url+'user/getproject/'+id);
  }
  getUserAccess(id:any){
    return this.http.get(this.url+'user/getaccess/'+id);
  }
  getUserDepartments(id:any){
    return this.http.get(this.url+'user/getdepartment/'+id);
  }

  getAdditionalData(id:any=0){
    return this.http.get(this.url+'user/getAdditionalData/'+id);
  }

  saveAdditionalData(data:any=[]){
    return this.http.post(this.url+'user/saveAdditionalData/', data);
  }

  
  

  // notifications
  getNotification(){
    return this.http.get(this.url+'notification/user');
  }
  readAllNotification(){
    this.http.get(this.url+'notification/read/').subscribe(e => {
      
    });
  }

  
  changeLogin(userid: any = {}){
    return this.http.post(this.url+'user/login', {id: userid});
  }

 
  getUserProfile(){
    
    var uprofile = localStorage.getItem('user_profile');
    if(uprofile!=null){
      var encprofile = this.config.decrypt(uprofile);
      var parse = JSON.parse(encprofile);
      this.user_profile = parse;
      // console.log(this.user_profile);
      return parse;
    }else{
      return null;
    }
    
  }


  all_access:any = {};
  setUserAccess(){
// module_access
// ship_access
    
    var module_access = localStorage.getItem('module_access');

    if(module_access!=null){
      var encprofile = this.config.decrypt(module_access);
      var parse = JSON.parse(encprofile);
      this.all_access.module_access = parse;
    }

    // module account type access
    var module_account_type = localStorage.getItem('module_account_type');

    if(module_account_type!=null){
      var encprofile = this.config.decrypt(module_account_type);
      var parse = JSON.parse(encprofile);
      this.all_access.module_account_type = parse;
    }
    
    var ship_access = localStorage.getItem('ship_access');
    if(ship_access!=null){
      var encprofile = this.config.decrypt(ship_access);
      var parse = JSON.parse(encprofile);
      this.all_access.ship_access = parse;
    }
  }

 

  checkEmailIfExist(email:string=''){
    return this.http.post(this.url+'auth/request_forgotpass', {email:email});
  }
  changePW(user:any={}){
    return this.http.post(this.url+'auth/change_password', user);
  }
  checkJWT(token:string=''){
    return this.http.post(this.url+'auth/check_jwt', {token:token});
  }

  login(formData: any = {}){
    return this.http.post(this.url+'auth/login', formData);
  }
  ssoLogin(token_id: any = {}){
    return this.http.post(this.url+'auth/ssologin', {token_id: token_id});
  }

  defaultCallback(cb:any){
    // alert();
    // console.log(t2);
    // return {t1, t2};
   }

  checkAccess = (modules:any = [], module_code:string = '', cb:any = this.defaultCallback)=>{
    if(this.user_profile?.position>=90) return cb(true)
    let data:any = this.globalservice.getData(modules, 'code', module_code);
    if(!data) return cb(false)  
    if(!this.all_access?.module_access?.includes(data.id)) return cb(false)
    if(!this.all_access?.module_account_type.includes(data.id)) return cb(false)
    return cb(true) 
  };

  logout(){
    this.http.get(this.config.getConfig().apiurl+'user/logout').subscribe(e=> {
      
    });
    this.cookieService.logout();
 
    this.globalservice.Route('login');
  }





}
