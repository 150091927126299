import { Component,EventEmitter, OnInit,Input,Output, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { switchMapWithLoading } from '../../services/switch-with-map-loading';
import { Observable, Subject,of  } from 'rxjs';
import { distinctUntilChanged,debounceTime,switchMap,map, filter } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmationService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
    selector: 'azure-input-search',
    templateUrl: './azure-input-search.component.html',
    styleUrls: ['../azure-multiple-selection/azure-multiple-selection.component.scss'],
    standalone: true,
    imports: [ConfirmDialogModule, NgIf, FormsModule, NgFor, AsyncPipe]
})
export class AzureInputSearchComponent implements OnInit {
  @ViewChild('menuDropdownEl', { read: ElementRef, static: false })  menuDropdownEl!: ElementRef;
 
  constructor(private commonService: CommonService, private renderer:Renderer2) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if(this.menuDropdownEl){
        if(!this.menuDropdownEl.nativeElement.contains(e.target)){
          if (!this.menuBtnClick ) {
            this.menuOpen = false;
          }
          this.menuBtnClick = false;
        }
      }
      
    });
   }

  @Input() col_class:string ='col';
  
  @Input()appendTo:string = '';
  @Output() selectAzureUserEvent = new EventEmitter<any>();
  dropdownOpen:Boolean = false;
  withRefresh = false;
  azureUsers$!: Observable<any>;
  public searchText$ = new Subject<string>();
  userAssignmentDialog:Boolean = false;
  searching:Boolean = false;
  searchValue:string ='';
  selectedUser:any = {};
  @Input()model:any;
  @Input()viewType:string = 'form';
  @ViewChild('searchEl') searchElement!: ElementRef;

  @ViewChild('azureElem') azureElem!: ElementRef;
  searchStyle:string = '';

  @Input()required:boolean = false;
  @Input() disabled:boolean = false;

  ngOnChanges(val:any){
    setTimeout(() => {
     
    
    if(this.model){
      let userDetails = JSON.parse(val.model.currentValue);
      this.selectedUser = userDetails;
      this.noData = false;
    }else{
      this.selectedUser = {};
      this.noData = true;
    }
  }, 200);
  }

  ngOnInit(): void {
      // azure users
      this.azureUsers$ = this.searchText$.pipe(
        filter(user=> user !== null && user !== ''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMapWithLoading(user=>
           this.commonService.getAzureUsers(user)
          )
      );
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(user: string) {
    this.searchText$.next(user);
  }

  onClickUser(e:any){
    this.noData = false;
    this.searchValue = e.displayName;
    this.selectedUser = e;
    this.selectAzureUserEvent.emit(JSON.stringify(e));
  }


  onClose(){
    this.searchValue = '';
    this.searchText$.next('');
  }

  onClearSearch(){
    this.searchValue = '';
    this.searchText$.next('');
}

  noData:boolean = true;
  onRemoveValue(){
    setTimeout(() => {
      this.noData = true;
      this.searchValue = '';
      this.selectedUser = {};
      this.selectAzureUserEvent.emit('');
    }, 200);
  }

  //to control outside click
  menuOpen: boolean = false;
  menuBtnClick: boolean = false;

  toggleMenu() {
    this.menuOpen = this.menuOpen?false:true;

    if(this.menuOpen){
      this.searchValue = '';
      this.searchText$.next('');
      if(this.appendTo=='body'){
        let azureRect:any = this.azureElem.nativeElement.getBoundingClientRect();
        this.searchStyle = `z-index: 1001;transform-origin: center top;top: ${azureRect.top+azureRect.height}px;position: fixed;width: ${azureRect.width}px;`
      }

   

    }
  }
  preventCloseOnClick() {
    this.menuBtnClick = true;
  }



}
